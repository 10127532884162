import  './PacingRatioDiffFormat.css'
import * as React                     from 'react'
import {Typography}                   from '@material-ui/core'
import {Translate}                    from "react-localize-redux"

interface PacingRatioDiffFormatProps {
  prevRatio : number
  ratio     : number
  channel  : string
}

const PacingRatioDiffFormat = (props:PacingRatioDiffFormatProps) => {
  const isEqual = props.prevRatio === props.ratio
  return (
    <div className="Grid">
      <div>
      <Typography className="Channel" color="textSecondary">
        <Translate id={props.channel}/>
      </Typography>
      </div>
      <div>
        {isEqual
          ? <Typography color="textSecondary">{props.ratio ?? "- "}%</Typography>
          : <Typography color="textSecondary"><Translate id={"pacyChangesHistory.from"}/> {props.prevRatio ?? "- "}% <Translate id={"pacyChangesHistory.to"}/> {props.ratio ?? "- "}%</Typography>
        }
      </div>
    </div>
  )
}

export default PacingRatioDiffFormat
