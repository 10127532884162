import {UpdatePacingRatioConfigDecision} from "./Decision"

export interface PacingRatioConfig {
  allActive : boolean,
  all       : number,
  pmax      : number,
  search    : number,
  social    : number,
}

export const DEFAULT_PACING_RATIO_CONFIG : PacingRatioConfig = {
  allActive : true,
  all       : 100,
  pmax      : 100,
  search    : 100,
  social    : 100
}

export const getPreviousPacingRatioConfigFromDecision = (decision:UpdatePacingRatioConfigDecision, decisions:UpdatePacingRatioConfigDecision[]):PacingRatioConfig => {
  let previous:PacingRatioConfig

  function checkIndex(element) {
    return element.id === this
  }
  let index = decisions.findIndex(checkIndex, decision.id)

  if (index > -1 && decisions[index+1] !== undefined) {
    previous = decisions[index+1].details.pacingRatioConfig
  } else {
    previous = DEFAULT_PACING_RATIO_CONFIG
  }

  return previous
}
