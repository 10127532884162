import './ProxiesPanel.css'
import * as React                       from 'react'
import {
  ProxyGroup,
  ProxyGroupsToAgentsTree
}                                       from '../../../../model/pacy/ProxyGroup'
import {
  Proxy,
  PossibleAgent
}                                       from '../../../../model/pacy'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Icon,
  Button,
  Modal,
  Card,
  Typography
}                                       from '@material-ui/core'
import ExpandMoreIcon                   from '@material-ui/icons/ExpandMore'
import AgentsTable                      from './AgentsTable'
import EditableMuiText                  from '../../../../components/EditableMuiText/EditableMuiText'
import ProxyParams                      from './ProxyParams'
import {
  AccountModifier,
  createSetProxyNameAccountModifier,
  createMoveProxyAccountModifier,
}                                       from '../../../../model/pacy/AccountModifiers'
import ProxyMove                        from './ProxyMove'
import { Translate }                    from 'react-localize-redux';
import ProxyGroupSharingPie             from './Components/ProxyGroupSharingPie'
import { StyledBadge, useStyles }       from "../../../../components/notifications/StyledBadge"

interface ProxiesPanelOwnProps {
  moveProxySimulation        : (newActions:AccountModifier[],moveInvestment:boolean) => void
  addAccountModifiers        : (newActions:AccountModifier[])=>void
  proxies                    : Proxy[]
  proxyGroup                 : ProxyGroup
  proxyGroups                : ProxyGroup[]
  proxyGroupId               : string
  possibleAgents             : PossibleAgent[]
  activeProvidersAlertTree   : ProxyGroupsToAgentsTree
}
interface ProxiesPanelProps extends ProxiesPanelOwnProps {}

const ProxiesPanel = (props:ProxiesPanelProps) => {
  const [openModal,setOpenModal] = React.useState(false)
  const [newProxyGroupId,setNewProxyGroupId] = React.useState(null)
  const [proxyToMoveId,setProxyToMoveId] = React.useState('')
  const [moveInvestment,setMoveInvestment] = React.useState(null)

  const handleMoveInvestment = (moveInvestment: boolean) => {
    setMoveInvestment(moveInvestment)
  }

  const handleSelectChange = (event) => {
    props.moveProxySimulation([createMoveProxyAccountModifier(true,proxyToMoveId,event.target.value,props.proxyGroupId)],true)
    props.moveProxySimulation([createMoveProxyAccountModifier(false,proxyToMoveId,event.target.value,props.proxyGroupId)],false)
    setNewProxyGroupId(event.target.value)
  }

  const onSaveFuntion = () => {
    props.addAccountModifiers([createMoveProxyAccountModifier(moveInvestment,proxyToMoveId,newProxyGroupId,props.proxyGroupId)])
  }

  const handleProxyGroupClick = (event,proxyGroupId:string) => {
    setNewProxyGroupId(proxyGroupId)
    props.moveProxySimulation([createMoveProxyAccountModifier(true,proxyToMoveId,proxyGroupId,props.proxyGroupId)],true)
    props.moveProxySimulation([createMoveProxyAccountModifier(false,proxyToMoveId,proxyGroupId,props.proxyGroupId)],false)
  }
  const handleOpenProxyMove = (event,proxyId:string) => {
    setProxyToMoveId(proxyId)
    event.stopPropagation()
    setOpenModal(true)
  }
  const handleCloseProxyMove = () => {
    setOpenModal(false)
    setNewProxyGroupId(null)
    setMoveInvestment(null)
  }
  const handleNameChange = (event,proxyId:string) => {
    props.addAccountModifiers([createSetProxyNameAccountModifier(proxyId,event.target.value,props.proxyGroupId)])
  }

  const proxiesWithAdjustedWeights = props.proxies.map((proxy) => {
    if (!proxy.active) {
      return {
        ...proxy,
        weight: 0
      }
    }
    return proxy
  })

  const totalUnadjustedWeight = props.proxies.reduce((weightAcc, proxy) => {
    return weightAcc + proxy.weight
  }, 0)
  const totalAdjustedWeight = proxiesWithAdjustedWeights.reduce((weightAcc, proxy) => {
    return weightAcc + proxy.weight
  }, 0)


  const unadjustedStartingAngles = []
  const adjustedStartingAngles = []

  for (const index in proxiesWithAdjustedWeights) {
    adjustedStartingAngles.push(parseInt(index) === 0 ?
        (proxiesWithAdjustedWeights[index].weight/totalAdjustedWeight)*360
      : adjustedStartingAngles[parseInt(index) - 1] + (proxiesWithAdjustedWeights[index].weight/totalAdjustedWeight)*360
    )
  }
  for (const index in props.proxies) {
    unadjustedStartingAngles.push(parseInt(index) === 0 ?
        (props.proxies[index].weight/totalUnadjustedWeight)*360
      : unadjustedStartingAngles[parseInt(index) - 1] + (props.proxies[index].weight/totalAdjustedWeight)*360
    )
  }

  const classes = useStyles()
  return (
  <div className="ProxiesPanel">
    {props.proxies.map((proxy,i)=>(
      <ExpansionPanel TransitionProps={{mountOnEnter:true}} className="ProxyExpansionPanel" key={i}>
        <ExpansionPanelSummary
          className="ProxyExpansionPanelSummary"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <div style={{display:'flex',alignItems:'center'}}>
            <Icon style={proxy.active ? {color:'green'} : {color:'red'}}>
                {proxy.active ? 'play_circle_filled' : 'pause_circle_filled'}
            </Icon>&nbsp;
            <div className="EditableMuiTextContainer">
              <EditableMuiText
                content={proxy.name}
                handleInputChange={(event)=>handleNameChange(event,proxy.id)}
                type="textArea"
                baliseType="h6"
                iconStyle={{color:'#CCC'}}
                maxLength={128}
              />
              <StyledBadge
                classes={{badge: classes.badgeProxyGroup}}
                badgeContent={" "}
                color="secondary"
                invisible={props.activeProvidersAlertTree[props.proxyGroupId]?.[proxy.id] ? false : true}
              ></StyledBadge>
            </div>
          </div>
          <div className="PieChart">
            <Typography variant="body1">
              <Translate id="pacyConfigs.weight" />:
            </Typography>
            <ProxyGroupSharingPie
              proxy={proxy}
              adjustedWeightProxy={proxiesWithAdjustedWeights.find((adjustedProxy) => adjustedProxy.id === proxy.id)}
              proxyGroup={props.proxyGroup}
              startingAngle={adjustedStartingAngles[i]}
              unadjustedStartingAngle={unadjustedStartingAngles[i]}
            />
          </div>
          <Button onClick={(event)=>handleOpenProxyMove(event,proxy.id)} color="primary">
            <Translate id="pacyConfigs.move" />
            <Icon>double_arrow</Icon>
          </Button>
       </ExpansionPanelSummary>
       <ExpansionPanelDetails className="ProxyDetails">
         <ProxyParams
          proxyGroupId={props.proxyGroupId}
          proxy={proxy}
          addAccountModifiers={props.addAccountModifiers}
         />
         <AgentsTable
          proxyGroups={props.proxyGroups}
          proxy = {proxy}
          addAccountModifiers={props.addAccountModifiers}
          proxyGroup={props.proxyGroup}
          proxyGroupId={props.proxyGroupId}
          possibleAgents={props.possibleAgents}
          activeProvidersAlertTree={props.activeProvidersAlertTree}
         />
       </ExpansionPanelDetails>
     </ExpansionPanel>
     ))}
     <Modal className="ProxyMoveModal" open={openModal} onClose={handleCloseProxyMove}>
       <Card>
        <ProxyMove
          setNewProxyGroupId={handleProxyGroupClick}
          handleSelectChange={handleSelectChange}
          proxyGroups = {props.proxyGroups}
          newProxyGroupId={newProxyGroupId}
          proxyGroupId={props.proxyGroupId}
          proxyGroupChannel={props.proxyGroup.channel}
          moveInvestment={moveInvestment}
          handleMoveInvestment={handleMoveInvestment}
          closeModal={handleCloseProxyMove}
          saveFunction={onSaveFuntion}
          proxyToMoveId={proxyToMoveId}
        />
       </Card>
     </Modal>
   </div>
  )
}
export default ProxiesPanel
