import './CreateAgentStep1.css'
import * as React                             from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Icon,
  Divider,
  Switch,
}                                             from '@material-ui/core'
import ArrayUtils                             from "../../../../../../utils/ArrayUtils"
import {
  ProxyGroupPossibleChannel
}                                             from '../../../../../../model/pacy/ProxyGroup'
import {
  Agent,
  AgentCompatibleProvider,
  PossibleAgent,
  filterPossibleAgentsToProviderChannel
}                                             from '../../../../../../model/pacy'
import CreateAgentParams                      from './CreateAgentParams'
import AgentWeightsConfiguration              from '../../AgentWeightsConfiguration'
import {
  AccountModifier,
  createAddAgentAccountModifier,
  createToggleAgentAccountModifier,
  createSetAgentWeightAccountModifier,
  generate
}                                             from '../../../../../../model/pacy/AccountModifiers'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                             from 'react-localize-redux'
import TwoPartsWithLogoButton                 from '../../../../../../components/Button/TwoPartsWithLogoButton'
import ProviderIcon                           from "./ProviderIcon"

interface CreateAgentStep1OwnProps {
  possibleAgents : PossibleAgent[]
  proxyGroupChannel : ProxyGroupPossibleChannel
  selectProvider : (provider:AgentCompatibleProvider)=>void
  onCancel       : ()=>void
}
interface CreateAgentStep1Props extends CreateAgentStep1OwnProps {
  translate : TranslateFunction
}

const CreateAgentStep1 = (props:CreateAgentStep1Props) => {
  return (
    <Card className="CreateAgentStep1">
      <CardHeader
        title={
          <div className="CreateAgentStep1Header">
            <Typography variant="h6" style={{display:'flex',alignItems:'center'}}>
              <Icon style={{marginRight:5}}>add</Icon>
              <Translate id="pacyConfigs.createAgent" />
            </Typography>
          </div>
        }
      />
      <Divider />
      <CardContent className="Content">
        <Typography variant="subtitle1" style={{}}>
          <Translate id="pacyConfigs.selectProvider"/>
        </Typography>
        <div className="ProviderList">
          {Object.values(AgentCompatibleProvider).map(provider=>{
            const providerChannelPossibleAgents = filterPossibleAgentsToProviderChannel(props.possibleAgents, provider, props.proxyGroupChannel)
            return (
              <TwoPartsWithLogoButton
                key={provider}
                logo={<ProviderIcon provider={provider}/>}
                topText={provider}
                bottomText={props.translate("pacyConfigs.possibleAgentsCount", {count: providerChannelPossibleAgents.length}) as string}
                onClick={()=>props.selectProvider(provider)}
                disabled={providerChannelPossibleAgents.length === 0}
              />
            )
          })}
        </div>
        <div style={{paddingTop:20}}>
          <Typography variant="caption">
            <Translate id="pacyConfigs.sameChannel"/>
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardActions className="CreateAgentStep1Footer">
        <Button onClick={props.onCancel} variant="outlined" color="secondary" size="large">
          <Translate id="common.cancel" />
        </Button>
      </CardActions>
    </Card>
  )

}

export default withLocalize(CreateAgentStep1 as any) as React.ComponentType<CreateAgentStep1OwnProps>


