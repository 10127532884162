import './ProxyGroupExpansion.css'
import * as React                           from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Button,
  Icon,
  ExpansionPanelDetails,
  Modal,
  Card,
  CardContent,
  Divider,
  CardActions,
  Typography,
}                                           from '@material-ui/core'
import ExpandMoreIcon                       from '@material-ui/icons/ExpandMore'
import EditableMuiText                      from '../../../../components/EditableMuiText/EditableMuiText'
import ProxyGroupParams                     from './ProxyGroupParams'
import ProxiesPanel                         from './ProxiesPanel'
import ProxyGroup                           from '../planner/ProxyGroup'
import {
  AccountModifier,
  createSetProxyGroupNameAccountModifier,
  createAddVagueGuidelineAccountModifier,
  createAddPreciseGuidelineAccountModifier
}                                           from '../../../../model/pacy/AccountModifiers'
import {
  ProxyGroup as ProxyGroupInterface,
  ProxyGroupsToAgentsTree
}                                           from '../../../../model/pacy/ProxyGroup'
import {
  Guideline,
  isVagueGuideline,
  isPreciseGuideline
}                                           from '../../../../model/pacy/Guideline'
import {
  PossibleAgent
}                                           from '../../../../model/pacy/'
import Period                               from '../../../../model/Period'
import { StringMap }                        from '../../../../model/generics'
import BudgetStats                          from '../../../../model/Store/Statistics/BudgetStats'
import moment                               from 'moment'
import { Translate }                        from 'react-localize-redux'
import {
  StyledBadge,
  useStyles,
  StyledBadgeTooltip
}                                           from "../../../../components/notifications/StyledBadge"

interface ProxyGroupExpansionOwnProps {
  proxyGroups               : ProxyGroupInterface[]
  proxyGroup                : ProxyGroupInterface
  possibleAgents            : PossibleAgent[]
  addAccountModifiers       : (newActions:AccountModifier[]) => void
  moveProxySimulation       : (newActions:AccountModifier[],moveInvestment:boolean) => void
  requestReportingDataLoad  : (period:Period)=>boolean
  statsByMonthByProxyGroup  : StringMap<StringMap<BudgetStats[]>>
  activeProvidersAlertTree  : ProxyGroupsToAgentsTree
}
interface ProxyGroupExpansionProps extends ProxyGroupExpansionOwnProps {}

const ProxyGroupExpansion = (props:ProxyGroupExpansionProps) => {
  const [guidelinesToAdd,setGuidelinesToAdd] = React.useState<{[month:string]:Guideline}>({})
  const [openSetAmount,setOpenSetAmount] = React.useState(false)
  const handleCloseSetAmount = () => {
    setOpenSetAmount(false)
  }
  const handleProxyGroupNameChange = (event,proxyGroupId:string) => {
    const name = event.target.value
    props.addAccountModifiers([createSetProxyGroupNameAccountModifier(proxyGroupId, name)])
  }
  const handleOpenSetAmount = (event,proxyGroupId:string) => {
    event.stopPropagation()
    setGuidelinesToAdd({})
    setOpenSetAmount(true)
  }
  const  handleAddGuideline = (guideline:Guideline) => {
    setGuidelinesToAdd(prevState=>({...prevState,[guideline.month+"-"+guideline.year]:{...guideline,createdOn:moment.utc().format("YYYY-MM-DD HH:mm:ss")}}))
  }
  const handleResetGuidelinesToAdd = () => {
    setGuidelinesToAdd({})
  }
  const handleDoneAddGuideLine = () => {
    if(Object.keys(guidelinesToAdd).length > 0){
      const actions = Object.values<Guideline>(guidelinesToAdd).map(guideline=>{
        if(isVagueGuideline(guideline)) {
          return createAddVagueGuidelineAccountModifier(props.proxyGroup.id,guideline.year,guideline.month,guideline.amount)
        }
        if(isPreciseGuideline(guideline)) {
          return createAddPreciseGuidelineAccountModifier(props.proxyGroup.id,guideline.year,guideline.month,guideline.amounts)
        }
      })
      props.addAccountModifiers(actions as AccountModifier[])
      handleResetGuidelinesToAdd()
    }
    handleCloseSetAmount()
  }
  const classes = useStyles()
  return (
    <div className="ProxyGroupExpansionPanel">
      <ExpansionPanel TransitionProps={{mountOnEnter:true}}>
        <ExpansionPanelSummary
          className="ExpansionPanel"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="Channel">
            <Typography className="ChannelText" variant="h6" color="primary">
              <Translate id={`pacyConfigs.channels.${props.proxyGroup.channel}`} />
            </Typography>
          </div>
          <div className="EditableMuiTextContainer">
            <EditableMuiText
              baliseType="h6"
              handleInputChange={(e)=>handleProxyGroupNameChange(e, props.proxyGroup.id)}
              content={props.proxyGroup.name}
              contentStyle={{ fontWeight: 400, color: "#212121" }}
              iconStyle={{ fontWeight: 400, color: "rgb(204, 204, 204)" }}
              inputStyle={{ fontWeight: 400, color: "#212121" }}
              type="input"
              maxLength={128}
            />
            <StyledBadgeTooltip title={<Translate id="pacyConfigs.pacyV0Alert" />} placement="top" arrow>
              <StyledBadge
                  classes={{badge: classes.badgeProxyGroup}}
                  badgeContent={" "}
                  color="secondary"
                  invisible={props.activeProvidersAlertTree[props.proxyGroup.id] ? false : true}
              >
              </StyledBadge>
            </StyledBadgeTooltip>
          </div>
          <Button disabled={props.proxyGroup.proxies.length === 0} onClick={(event)=>{handleOpenSetAmount(event,props.proxyGroup.id)}} color="primary" style={{display:'flex',alignItems:'center'}}>
            <Icon>attach_money</Icon>
            <Translate id="pacyConfigs.amounts" />
          </Button>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="ProxyGroup">
          <ProxyGroupParams
            proxyGroups={props.proxyGroups}
            proxyGroup={props.proxyGroup}
            addAccountModifiers={props.addAccountModifiers}
          />
          <ProxiesPanel
            moveProxySimulation={props.moveProxySimulation}
            addAccountModifiers={props.addAccountModifiers}
            proxies={props.proxyGroup.proxies}
            proxyGroups={props.proxyGroups}
            proxyGroup={props.proxyGroup}
            proxyGroupId={props.proxyGroup.id}
            possibleAgents={props.possibleAgents}
            activeProvidersAlertTree={props.activeProvidersAlertTree}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Modal className="SetAmountModal" open={openSetAmount} onClose={handleCloseSetAmount}>
        <Card className="SetAmountModalContainer">
          <CardContent>
            <ProxyGroup
              requestReportingDataLoad={props.requestReportingDataLoad}
              proxyGroup={{...props.proxyGroup,guidelines:[...props.proxyGroup.guidelines,...Object.values(guidelinesToAdd)]}}
              budgetStatsByMonth={props.statsByMonthByProxyGroup[props.proxyGroup.id]}
              noGraph
              color="#00B2E3"
              addGuideline={handleAddGuideline}
              hideVagueConfirmModal
            />
          </CardContent>
          <Divider />
          <CardActions className="SetAmountModalFooter">
            <Button onClick={handleCloseSetAmount} color="secondary" variant="outlined" size="medium">
              <Translate id="common.cancel" />
            </Button>
            <Button
              onClick={handleDoneAddGuideLine}
              style={{color:'white'}}
              color="primary"
              variant="contained"
              size="medium"
            >
              <Translate id="pacyConfigs.done" />
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  )
}

export default ProxyGroupExpansion
