import {
  BeginningOfMonthShape,
  PacyAccount,
  BeginningOfMonthBoostConfig,
  AgentCompatibleProvider,
  AgentCompatibleChannel,
}                                 from "."
import { createGenerator }        from "../../utils/IdGeneratorUtils"
import {
  ProxyGroupPossibleChannel
}                                 from "./ProxyGroup"

export const {generate,reset}   = createGenerator()
export const resetGenerator     = reset


export interface BaseAccountModifier {
  type : AccountModifierType
}
export enum AccountModifierType {
  "SET_AGENT_MAXSHARING"          = "SET_AGENT_MAXSHARING",
  "SET_PROXY_MAXSHARING"          = "SET_PROXY_MAXSHARING",
  "SET_BOMB"                      = "SET_BOMB",
  "SET_PROXY_WEIGHT"              = "SET_PROXY_WEIGHT",
  "SET_AGENT_WEIGHT"              = "SET_AGENT_WEIGHT",
  "SET_PROXY_NAME"                = "SET_PROXY_NAME",
  "SET_PROXYGROUP_NAME"           = "SET_PROXYGROUP_NAME",
  "SET_PROXYGROUP_DESCRIPTION"    = "SET_PROXYGROUP_DESCRIPTION",
  "SET_PROXYGROUP_CHANNEL"        = "SET_PROXYGROUP_CHANNEL",
  "TOGGLE_PROXY"                  = "TOGGLE_PROXY",
  "TOGGLE_AGENT"                  = "TOGGLE_AGENT",
  "MOVE_PROXY"                    = "MOVE_PROXY",
  "MOVE_AGENT"                    = "MOVE_AGENT",
  "ADD_AGENT"                     = "ADD_AGENT",
  "ADD_PROXY"                     = "ADD_PROXY",
  "ADD_PROXYGROUP"                = "ADD_PROXYGROUP",
  "DELETE_AGENT"                  = "DELETE_AGENT",
  "DELETE_PROXY"                  = "DELETE_PROXY",
  "DELETE_PROXYGROUP"             = "DELETE_PROXYGROUP",
  "TOGGLE_PROXYGROUP_SHARING"     = "TOGGLE_PROXYGROUP_SHARING",
  "ADD_VAGUE_GUIDELINE"           = "ADD_VAGUE_GUIDELINE",
  "ADD_PRECISE_GUIDELINE"         = "ADD_PRECISE_GUIDELINE"
}
export type AccountModifier = (
  |AddPreciseGuidelineAccountModifier
  |AddVagueGuidelineAccountModifier
  |AddProxyGroupAccountModifier
  |AddProxyAccountModifier
  |AddAgentAccountModifier
  |MoveAgentAccountModifier
  |MoveProxyAccountModifier
  |SetAgentMaxSharingAccountModifier
  |SetProxyMaxSharingAccountModifier
  |SetBOMBAccountModifier
  |SetProxyWeightAccountModifier
  |SetAgentWeightAccountModifier
  |SetProxyNameAccountModifier
  |SetProxyGroupNameAccountModifier
  |SetProxyGroupDescriptionAccountModifier
  |SetProxyGroupChannelAccountModifier
  |ToggleProxyAccountModifier
  |ToggleAgentAccountModifier
  |ToggleProxyGroupSharingAccountModifier
)
//Agent Max Sharig Modifier
export interface SetAgentMaxSharingAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_AGENT_MAXSHARING
  readonly agentId      : string
  readonly proxyId      : string
  readonly proxyGroupId : string
  readonly maxSharing   : number
}
export const createSetAgentMaxSharingAccountModifier = (agentId:string,maxSharing:number,proxyGroupId:string,proxyId:string):SetAgentMaxSharingAccountModifier => ({
  type : AccountModifierType.SET_AGENT_MAXSHARING,
  agentId,
  maxSharing,
  proxyGroupId,
  proxyId
})

//Proxy Max Sharing Modifier
export interface SetProxyMaxSharingAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_PROXY_MAXSHARING
  readonly proxyId      : string
  readonly maxSharing   : number
  readonly proxyGroupId : string
}
export const createSetProxyMaxSharingAccountModifier = (proxyId:string,maxSharing:number,proxyGroupId:string):SetProxyMaxSharingAccountModifier => ({
type : AccountModifierType.SET_PROXY_MAXSHARING,
  proxyId,
  maxSharing,
  proxyGroupId
})

//Begining of month boost modifier
export interface SetBOMBAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_BOMB
  readonly proxyGroupId : string
  readonly proxyId      : string
  readonly active       : boolean
  readonly boost        : number
  /**
   * @minimum 0
   * @TJS-type integer
   */
  readonly length   : number
  readonly shape    : keyof typeof BeginningOfMonthShape
}
export const createSetBOMBAccountModifier = (proxyId:string,active:boolean,boost:number,length:number,shape:keyof typeof BeginningOfMonthShape,proxyGroupId:string):SetBOMBAccountModifier => ({
  type : AccountModifierType.SET_BOMB,
  proxyId,
  active ,
  boost,
  length,
  shape,
  proxyGroupId
})

//Proxy Weight
export interface SetProxyWeightAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_PROXY_WEIGHT
  readonly proxyId      : string
  readonly weight       : number
  readonly proxyGroupId : string
}
export const createSetProxyWeightAccountModifier = (proxyId:string,weight :number,proxyGroupId:string):SetProxyWeightAccountModifier => ({
  type    : AccountModifierType.SET_PROXY_WEIGHT,
  proxyId : proxyId,
  weight  : weight,
  proxyGroupId
})

//Agent Weight
export interface SetAgentWeightAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_AGENT_WEIGHT
  readonly agentId      : string
  readonly weight       : number
  readonly proxyGroupId : string
  readonly proxyId      : string
}
export const createSetAgentWeightAccountModifier = (agentId:string,weight:number,proxyId:string,proxyGroupId:string):SetAgentWeightAccountModifier => ({
  type    : AccountModifierType.SET_AGENT_WEIGHT,
  agentId : agentId,
  weight  : weight,
  proxyId,
  proxyGroupId
})

//Proxy Name
export interface SetProxyNameAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_PROXY_NAME
  readonly proxyId      : string
  readonly name         : string
  readonly proxyGroupId : string
}
export const createSetProxyNameAccountModifier = (proxyId:string,name:string,proxyGroupId:string):SetProxyNameAccountModifier => ({
  type    : AccountModifierType.SET_PROXY_NAME,
  proxyId : proxyId,
  name    : name,
  proxyGroupId,
})

//ProxyGroup Name
export interface SetProxyGroupNameAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_PROXYGROUP_NAME
  readonly proxyGroupId : string
  readonly name         : string
}
export const createSetProxyGroupNameAccountModifier = (proxyGroupId:string,name:string):SetProxyGroupNameAccountModifier => ({
  type : AccountModifierType.SET_PROXYGROUP_NAME,
  proxyGroupId : proxyGroupId,
  name : name
})

//ProxyGroup Description
export interface SetProxyGroupDescriptionAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_PROXYGROUP_DESCRIPTION
  readonly proxyGroupId : string
  readonly description  : string
}
export const createSetProxyGroupDescriptionAccountModifier = (proxyGroupId:string,description:string):SetProxyGroupDescriptionAccountModifier => ({
  type         : AccountModifierType.SET_PROXYGROUP_DESCRIPTION,
  proxyGroupId : proxyGroupId,
  description  : description
})

//ProxyGroup Channel
export interface SetProxyGroupChannelAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.SET_PROXYGROUP_CHANNEL
  readonly proxyGroupId : string
  readonly channel      : ProxyGroupPossibleChannel
}
export const createSetProxyGroupChannelAccountModifier = (proxyGroupId:string,channel:ProxyGroupPossibleChannel):SetProxyGroupChannelAccountModifier => ({
  type         : AccountModifierType.SET_PROXYGROUP_CHANNEL,
  proxyGroupId : proxyGroupId,
  channel      : channel
})

//Proxy toggle
export interface ToggleProxyAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.TOGGLE_PROXY
  readonly proxyId      : string
  readonly enable       : boolean
  readonly proxyGroupId : string
}
export const createToggleProxyAccountModifier = (id:string,enable:boolean,proxyGroupId:string):ToggleProxyAccountModifier => ({
  type    : AccountModifierType.TOGGLE_PROXY,
  proxyId : id,
  enable,
  proxyGroupId
})

//Agent Toggle
export interface ToggleAgentAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.TOGGLE_AGENT
  readonly agentId      : string
  readonly enable       : boolean
  readonly proxyId      : string
  readonly proxyGroupId : string
}
export const createToggleAgentAccountModifier = (agentId:string,enable:boolean,proxyId:string,proxyGroupId:string):ToggleAgentAccountModifier => ({
  type : AccountModifierType.TOGGLE_AGENT,
  agentId,
  proxyId,
  proxyGroupId,
  enable
})

//ProxyGroup Sharing Toggle
export interface ToggleProxyGroupSharingAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.TOGGLE_PROXYGROUP_SHARING
  readonly proxyGroupId : string
  readonly enable       : boolean
}
export const createToggleProxyGroupSharingAccountModifier = (id:string,enable:boolean):ToggleProxyGroupSharingAccountModifier => ({
  type          : AccountModifierType.TOGGLE_PROXYGROUP_SHARING,
  proxyGroupId  : id,
  enable
})

//Move Proxy
export interface MoveProxyAccountModifier extends BaseAccountModifier {
  readonly type            : AccountModifierType.MOVE_PROXY
  readonly moveInvestment  : boolean
  readonly proxyId         : string
  readonly newProxyGroupId : string
  readonly oldProxyGroupId : string
}
export const createMoveProxyAccountModifier =(moveInvestment:boolean,proxyId:string,newProxyGroupId:string,oldProxyGroupId:string):MoveProxyAccountModifier => ({
  type            : AccountModifierType.MOVE_PROXY,
  moveInvestment  : moveInvestment,
  proxyId         : proxyId,
  newProxyGroupId : newProxyGroupId,
  oldProxyGroupId : oldProxyGroupId
})

//Move Agent
export interface MoveAgentAccountModifier extends BaseAccountModifier {
  readonly type            : AccountModifierType.MOVE_AGENT
  readonly agentId         : string
  readonly newProxyGroupId : string
  readonly oldProxyGroupId : string
  readonly newProxyId      : string
  readonly oldProxyId      : string
}
export const createMoveAgentAccountModifier =
  (agentId:string,newProxyGroupId:string,oldProxyGroupId:string,newProxyId:string,oldProxyId:string):MoveAgentAccountModifier => ({
    type            : AccountModifierType.MOVE_AGENT,
    agentId         : agentId,
    newProxyGroupId : newProxyGroupId,
    oldProxyGroupId : oldProxyGroupId,
    newProxyId      : newProxyId,
    oldProxyId      : oldProxyId
})

export interface MoveProxySimulationAccount {
  movingInvestment    : PacyAccount
  notMovingInvestment : PacyAccount
}

//Add Proxy
export interface AddProxyAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.ADD_PROXY
  /**
   * @pattern ^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$
   */
  readonly proxyGroupId : string
  readonly proxy        : {
    /**
     * @pattern ^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$
     */
    readonly id         : string
    /**
     * @minLength 1
     */
    readonly name       : string
    /**
     * @minimum 0
     * @TJS-type integer
     */
    readonly weight     : number
    readonly active     : boolean
    readonly sharing    : boolean
    /**
     * @minimum 0
     * @maximum 1
     */
    readonly maxSharing : number
    readonly beginningOfMonthBoost : BeginningOfMonthBoostConfig
  }
}
export const createAddProxyAccountModifier = (proxyGroupId:string,proxy:{id:string,name:string,weight:number,active:boolean,sharing:boolean,maxSharing:number,beginningOfMonthBoost:BeginningOfMonthBoostConfig}):AddProxyAccountModifier => ({
  type : AccountModifierType.ADD_PROXY,
  proxyGroupId : proxyGroupId,
  proxy : {...proxy},
})

//Add Proxy Group

export interface AddProxyGroupAccountModifier extends BaseAccountModifier {
  readonly type       : AccountModifierType.ADD_PROXYGROUP
  readonly proxyGroup : {
    /**
     * @pattern ^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$
     */
    readonly id             : string
    /**
     * @TJS-type integer
     */
    readonly accountId      : number
    readonly channel        : ProxyGroupPossibleChannel
    /**
     * @minLength 1
     */
    readonly name           : string
    readonly description    : string
    readonly sharing        : boolean
  }
}
export const createAddProxyGroupAccountModifier = (proxyGroup:{accountId:number,channel:ProxyGroupPossibleChannel,name:string,description:string,sharing:boolean}):AddProxyGroupAccountModifier => ({
  type : AccountModifierType.ADD_PROXYGROUP,
  proxyGroup : {
    ...proxyGroup,
    id : generate(),
  }
})

//Add Agent
export interface AddAgentAccountModifier extends BaseAccountModifier {
  readonly type         : AccountModifierType.ADD_AGENT
  /**
   * @pattern ^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$
   */
  readonly proxyGroupId : string
   /**
   * @pattern ^(.F[0-9]+|[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})$
   */
  readonly proxyId      : string
  readonly agent        : {
    /**
     * @pattern ^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$
     */
    readonly id         : string
    readonly provider   : AgentCompatibleProvider
    readonly providerId : string
    readonly channel    : AgentCompatibleChannel
    /**
     * @minLength 1
     */
    readonly name       : string
    /**
     * @minimum 0
     * @TJS-type integer
     */
    readonly weight     : number
    readonly active     : boolean
    /**
     * @minimum 0
     * @maximum 1
     */
    readonly maxSharing : number
  }
}
export const createAddAgentAccountModifier = (
  proxyGroupId:string,
  proxyId:string,
  agent:{
    id          : string
    provider    : AgentCompatibleProvider
    providerId  : string
    channel     : AgentCompatibleChannel
    name        : string
    weight      : number
    active      : boolean
    maxSharing  : number
  }
):AddAgentAccountModifier => ({
  type : AccountModifierType.ADD_AGENT,
  proxyGroupId,
  proxyId,
  agent : {...agent},
})

//Precise Guideline
export interface AddPreciseGuidelineAccountModifier extends BaseAccountModifier {
  readonly type    : AccountModifierType.ADD_PRECISE_GUIDELINE
  /**
   * @pattern ^(.F[0-9]+|[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})$
   */
  readonly proxyGroupId : string
  /**
   * @pattern \d{4}
   */
  readonly year    : string
  /**
   * @pattern \d{2}
   */
  readonly month   : string
  readonly amounts : {
    /**
     * @minimum 0
     */
    readonly [proxyId:string] : number
  }
}
export const createAddPreciseGuidelineAccountModifier = (
  proxyGroupId  : string,
  year          : string,
  month         : string,
  amounts       : {[proxyId:string]:number}
):AddPreciseGuidelineAccountModifier => ({
  type : AccountModifierType.ADD_PRECISE_GUIDELINE,
  proxyGroupId,
  year,
  month,
  amounts
})

//Add vague guideline
export interface AddVagueGuidelineAccountModifier extends BaseAccountModifier {
  readonly type   : AccountModifierType.ADD_VAGUE_GUIDELINE
  /**
   * @pattern ^(.F[0-9]+|[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})$
   */
  readonly proxyGroupId : string
  /**
   * @pattern \d{4}
   */
  readonly year   : string
  /**
   * @pattern \d{2}
   */
  readonly month  : string
  /**
   * @minimum 0
   */
  readonly amount : number
}
export const createAddVagueGuidelineAccountModifier = (
  proxyGroupId  : string,
  year          : string,
  month         : string,
  amount        : number
) => ({
  type  : AccountModifierType.ADD_VAGUE_GUIDELINE,
  proxyGroupId,
  year,
  month,
  amount
})
