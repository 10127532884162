import * as React            from "react"
import {PacingRatioConfig}   from "../../../../../model/pacy/PacingRatioConfig"
import PacingRatioDiffFormat from './PacingRatioDiffFormat/PacingRatioDiffFormat'

interface PacingRatioDiffProps{
  pacingRatioConfig         : PacingRatioConfig
  previousPacingRatioConfig : PacingRatioConfig
}

const PacingRatioDiff = (props:PacingRatioDiffProps) => {
  // Previously and currently set to AllChannels Active
  if (props.previousPacingRatioConfig.allActive === props.pacingRatioConfig.allActive && props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.all} channel="pacingRatio.setup.NA" />
      </div>
    )
  }
  // Previously and currently set to AllChannels Inactive (so it's by channel)
  if (props.previousPacingRatioConfig.allActive === props.pacingRatioConfig.allActive && !props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.pmax} ratio={props.pacingRatioConfig.pmax} channel="pacingRatio.setup.pmax" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.search} ratio={props.pacingRatioConfig.search} channel="pacingRatio.setup.search" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.social} ratio={props.pacingRatioConfig.social} channel="pacingRatio.setup.social" />
      </div>
    )
  }
  // Previously set to AllChannels Active and currently set to AllChannels Inactive (so it's now by channel)
  if (props.previousPacingRatioConfig.allActive !== props.pacingRatioConfig.allActive && !props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.pmax} channel="pacingRatio.setup.pmax" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.search} channel="pacingRatio.setup.search" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.social} channel="pacingRatio.setup.social" />
      </div>
    )
  }
  // Previously set to AllChannels Inactive (so it was by channel) and currently set to AllChannels Active
  if (props.previousPacingRatioConfig.allActive !== props.pacingRatioConfig.allActive && props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.pmax} ratio={props.pacingRatioConfig.all} channel="pacingRatio.setup.pmax" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.search} ratio={props.pacingRatioConfig.all} channel="pacingRatio.setup.search" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.social} ratio={props.pacingRatioConfig.all} channel="pacingRatio.setup.social" />
      </div>
    )
  }
}

export default PacingRatioDiff
