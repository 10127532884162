import "./UpdatePacingRatio.css"
import * as React                                 from "react"
import moment                                     from "moment"
import {UpdatePacingRatioConfigDecision}          from "../../../../../model/pacy/Decision"
import {getPreviousPacingRatioConfigFromDecision} from "../../../../../model/pacy/PacingRatioConfig"
import PacingRatioDiff                            from "../PacingRatioDiff/PacingRatioDiff"
import {Typography}                               from '@material-ui/core'
import ExpansionPanel                             from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary                      from '@material-ui/core/ExpansionPanelSummary'
import {Translate}                                from "react-localize-redux"

interface  UpdatePacingRatioProps extends UpdatePacingRatioOwnProps {
}

interface UpdatePacingRatioOwnProps{
  decision : UpdatePacingRatioConfigDecision
  decisions : UpdatePacingRatioConfigDecision[]
  users : {[userId:string]: string}
}


class UpdatePacingRatio extends React.Component<UpdatePacingRatioProps,any>{
  shouldComponentUpdate(nextProps:UpdatePacingRatioProps, nextState){
    return this.props.decision.id !== nextProps.decision.id
  }
  render(){
    const pacingRatioConfig = this.props.decision.details.pacingRatioConfig
    const previousPacingRatioConfig = getPreviousPacingRatioConfigFromDecision(this.props.decision, this.props.decisions)
    const date = moment(this.props.decision.createdOn).format("LLLL") //Format the Date to Locale
    const userEmail = this.props.users[this.props.decision.details.userId] || this.props.decision.details.userId

    return (
      <ExpansionPanel TransitionProps={{mountOnEnter: true}}>
        <ExpansionPanelSummary>
          <div className="UpdatePacingRatio">
            <div className="Content">
              <div>
                <Typography className="title" color="textSecondary"><Translate id={"pacyChangesHistory.changeType.pacingRatioChange"}/></Typography>
                <Typography color="textSecondary">
                  Type:&nbsp;
                  {pacingRatioConfig.allActive ? <Translate id={"pacingRatio.setup.all"}/> : <Translate id={"pacyChangesHistory.channels"}/>}
                </Typography>
                <div className="Diff">
                  <div className="ChannelTitle">
                    <Typography color="textSecondary">
                      <Translate id={"pacyChangesHistory.channel"}/>:&nbsp;
                    </Typography>
                  </div>
                  <div className="Channels">
                    <PacingRatioDiff
                      pacingRatioConfig={pacingRatioConfig}
                      previousPacingRatioConfig={previousPacingRatioConfig}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Author">
              <Typography color="textSecondary"><em>{date}</em></Typography>
              <Typography color="textSecondary">{userEmail}</Typography>
            </div>
          </div>
        </ExpansionPanelSummary>
      </ExpansionPanel>
    )
  }
}

export default UpdatePacingRatio
