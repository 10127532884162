import Waze                from "../../utils/Waze"
import LogBuilder          from "../../controller/log/LogBuilder"
import UserActions         from "../../model/constant/UserAction"
import Actions             from "../../model/constant/actions"
import {getToken}          from "../../storeAccessor/Generic"

const NO_ACCOUNT_STATUS = "NO_WAZE_ACCOUNT_LINKED"
const getCompanyOfAccountSuccess = (response, accountId) => {
  if(response?.data?.status === NO_ACCOUNT_STATUS || !response?.data?.status){
    return {
      type      : Actions.RETRIEVE_WAZE_DATA_NO_ACCOUNT,
      accountId : accountId
    }
  }
  return {
    type      : Actions.RETRIEVE_ACCOUNT_WAZE_COMPANY,
    accountId : accountId,
    companyId : response.data.companyId
  }
}
export const getCompanyOfAccount = (accountId:number) => (dispatch, getState) => {
  Waze.get({
      action    : "GetCompanyOfAccount",
      token     : getToken(getState()),
      accountId : accountId
    },
    response=>dispatch(getCompanyOfAccountSuccess(response, accountId)),
    error=>console.log(error),
  )
}
export const assignCompany = (accountId:number, companyId:number) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.ASSIGN_WAZE_COMPANY,token,{accountId, companyId})
  Waze.get({
      action    : "AssignWazeToAccount",
      token     : token,
      companyId : companyId,
      accountId : accountId
    },
    response=>dispatch(getCompanyOfAccount(accountId)),
    error=>console.log(error)
  )
}
export const unassignCompany = (accountId:number) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.ASSIGN_WAZE_COMPANY,token,{accountId, companyId:null})
  Waze.get({
      action    : "UnassignWazeFromAccount",
      token     : token,
      accountId : accountId
    },
    response=>dispatch(getCompanyOfAccount(accountId)),
    error=>console.log(error)
  )
}
