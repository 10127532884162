import './pacyLoader.css'
import * as React                           from "react"
import moment                               from "moment"
import {connect}                            from "react-redux"
import {State}                              from "../../reducers/generic/reducerAssembly"
import AccountsAccessors                    from "../../storeAccessor/Accounts"
import {
  retrievePacyAccount,
  retrieveSpendAttempts,
  retrieveDecisions
}                                           from "../../actions/pacy/Account"
import {
  retrievePossibleAgents,
}                                           from "../../actions/pacy/PossibleAgent"
import {
  retrieveUsers
}                                           from "../../actions/pacy/Users"
import {retrieveV0Usage}                    from "../../actions/pacy/Alerts"
import retrieveBudgetStats                  from "../../actions/statistics/budgetsStats"
import ExplainedLoading                     from "../loading"
import ErrorBoundary                        from "../Error/ErrorBoundary"
import Period                               from "../../model/Period"
import {Translate}                          from "react-localize-redux"

const makeMapStateToProps = () => {
  const selectedAccountSelector = AccountsAccessors.makeSelectedAccountSelector()
  return (state:State,ownProps)=>{
    const selectedAccount = selectedAccountSelector(state)
    const accountId = state.Accounts.selected
    return {
      accountId,
      accountAWId                   : selectedAccount.AWCustomerId,
      accountBingId                 : selectedAccount.bingCustomerId,
      accountFacebookId             : selectedAccount.facebookAccountId,
      account                       : state.Pacy.Accounts[accountId],
      users                         : state.Pacy.Users[accountId],
      possibleAgents                : state.Pacy.PossibleAgents[accountId],
      failedAccount                 : state.Pacy.Failed.Accounts,
      retrievingAccounts            : state.Pacy.Retrieving.Accounts,
      retrievingStats               : state.Statistics.Retrieving.Budgets,
      retrievedStatsRange           : state.Statistics.RetrievedRange.Budgets.byDay[accountId],
      retrievingSpendAttempts       : state.Pacy.Retrieving.SpendAttempts,
      retrievedSpendAttemptsRange   : state.Pacy.RetrievedRange.SpendAttempts[accountId],
      retrievingDecisions           : state.Pacy.Retrieving.Decisions,
      retrievedDecisionsRange       : state.Pacy.RetrievedRange.Decisions[accountId] || [],
      retrievingUsers               : state.Pacy.Retrieving.Users,
      retrievingPossibleAgents      : state.Pacy.Retrieving.PossibleAgents,
      retrieveDecisionsAfterSaving  : state.Pacy.Retrieve.RetrieveDecisionsAfterSaving,
      retrieveV0UsageAfterSaving    : state.Pacy.Retrieve.RetrieveV0UsageAfterSaving,
      retrievingPacyV0Usage         : state.Alerts.Retrieving.PacyV0Usage,
    }
  }
}
const mapDispatchToProps = {
  retrieveAccount: retrievePacyAccount,
  retrieveBudgetStats,
  retrieveSpendAttempts,
  retrieveDecisions,
  retrieveUsers,
  retrievePossibleAgents,
  retrieveV0Usage,
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    retrieveAccount        : ()=>DP.retrieveAccount(SP.accountId),
    retrieveBudgetStats    : (period:Period)=>DP.retrieveBudgetStats(SP.accountId, SP.accountAWId, SP.accountBingId, SP.accountFacebookId, period),
    retrieveSpendAttempts  : (period:Period)=>DP.retrieveSpendAttempts(SP.accountId, period),
    retrieveDecisions      : (period:Period)=>DP.retrieveDecisions(SP.accountId, period),
    retrieveUsers          : ()=>DP.retrieveUsers(SP.accountId),
    retrievePossibleAgents : ()=>DP.retrievePossibleAgents(SP.accountId),
    retrieveV0Usage        : ()=>DP.retrieveV0Usage(SP.accountId),
  }
}

const isRetrievedRangeEnough = (range:Period, wanted:Period):boolean => {
  return (
    range !== undefined &&
    moment(range.dateTo).startOf("day") >= moment(wanted.dateTo).startOf("day") &&
    moment(range.dateFrom).startOf("day") <= moment(wanted.dateFrom).startOf("day")
  )
}
class PacyLoader extends React.Component<any>{
  private waitingReportingDataLoadFinished

  constructor(props){
    super(props)
    this.requestReportingDataLoad = this.requestReportingDataLoad.bind(this)
  }

  componentWillUnmount(){
    clearTimeout(this.waitingReportingDataLoadFinished)
  }

  requestReportingDataLoad(period:Period):boolean{
    let willRetrieve = false
    if(!this.props.failedAccount.status){ //Only retrieve data if the account load did not fail
      if(this.props.needStats && !isRetrievedRangeEnough(this.props.retrievedStatsRange, period)){
        if(this.props.retrievingStats){
          this.waitingReportingDataLoadFinished = setTimeout(()=>this.requestReportingDataLoad(period), 1000)
        }
        else{
          this.props.retrieveBudgetStats(period)
        }
        willRetrieve = true
      }
      if(this.props.needSpendAttempts && !isRetrievedRangeEnough(this.props.retrievedSpendAttemptsRange, period)){
        if(this.props.retrievingSpendAttempts){
          this.waitingReportingDataLoadFinished = setTimeout(()=>this.requestReportingDataLoad(period), 1000)
        }
        else{
          this.props.retrieveSpendAttempts(period)
        }
        willRetrieve = true
      }
      if(this.props.needDecisions && !isRetrievedRangeEnough(this.props.retrievedDecisionsRange, period)){
        if(this.props.retrievingDecisions){
          this.waitingReportingDataLoadFinished = setTimeout(()=>this.requestReportingDataLoad(period), 1000)
        }
        else{
          this.props.retrieveDecisions(period)
        }
        willRetrieve = true
      }
      if(this.props.retrieveDecisionsAfterSaving || !isRetrievedRangeEnough(this.props.retrievedDecisionsRange,period)) {
        if(this.props.retrievingDecisions) {
          this.waitingReportingDataLoadFinished = setTimeout(()=>this.requestReportingDataLoad(period), 1000)
        }
        else {
          this.props.retrieveDecisions(period)
        }
      }
    }
    return willRetrieve
  }

  returnErrorOnFailure(){
    if(this.props.failedAccount.status && !this.props.letThrough){
      // We want to show any error, except when Pacy account is not created yet (user will be redirected to migration page)
      if (!(this.props.failedAccount?.statusCode === 404 && this.props.failedAccount?.message === "No such account")) {
        return (
          <div className="PacyMessage">
            {
              this.props.failedAccount.message ||
              <Translate id="pacyPlanner.failed" />
            }
          </div>
        )
      }
    }
  }
  loadPacyAccount(loading:boolean){
    if(this.props.account === undefined && !this.props.failedAccount.status){
      if(!this.props.retrievingAccounts){this.props.retrieveAccount()}
      loading = true
    }
    return loading
  }
  loadV0Usage(loading:boolean){
    if(this.props.retrieveV0UsageAfterSaving) {
      if(!this.props.retrievingPacyV0Usage){this.props.retrieveV0Usage()}
      loading = true
    }
    return loading
  }
  loadUsers(loading:boolean){
    if(this.props.needUsers && this.props.users === undefined){
      if(!this.props.retrievingUsers){this.props.retrieveUsers()}
      loading = true
    }
    return loading
  }
  loadPossibleAgents(loading:boolean){
    if(this.props.needPossibleAgents && this.props.possibleAgents === undefined){
      if(!this.props.retrievingPossibleAgents){this.props.retrievePossibleAgents()}
      loading = true
    }
    return loading
  }
  loadBudgetStatsAndDecisions(loading:boolean){
    if(this.requestReportingDataLoad(this.props.defaultStatsNeeded) || this.props.retrievingStats){
      loading = true
    }
    return loading
  }
  render(){
    let loading = false
    const pacyError = this.returnErrorOnFailure()
    if(pacyError){
      return pacyError
    }
    loading = this.loadPacyAccount(loading)
    loading = this.loadV0Usage(loading) // important to stay here, outside the below conditions...
    // Once the account has loaded without failure, we can load the rest of the data (if the account is enabled or if we specified to load anyway)
    if(!this.props.failedAccount.status && this.props.account && (this.props.account.enabled || this.props.loadWhenInactive)){
      loading = this.loadUsers(loading)
      loading = this.loadPossibleAgents(loading)
      loading = this.loadBudgetStatsAndDecisions(loading)
    }
    if(!this.props.letThrough && loading){
      return <ExplainedLoading translateId="loadings.pacy"/>
    }
    return <ErrorBoundary>{this.props.render(this.props.account, this.requestReportingDataLoad, loading)}</ErrorBoundary>
  }
}
const PacyLoaderConnected = connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(PacyLoader)

interface LoaderConfig{
  defaultStatsNeeded ?: Period
  needStats          ?: boolean
  needSpendAttempts  ?: boolean
  needDecisions      ?: boolean
  needUsers          ?: boolean
  needPossibleAgents ?: boolean
  letThrough         ?: boolean
  loadWhenInactive   ?: boolean
}
const baseConfig = {
  defaultStatsNeeded : { // Need past 2 years + current month by default
    dateFrom : moment().subtract(24, "months").startOf("month").toDate(),
    dateTo   : moment().toDate()
  },
  needStats          : false,
  needSpendAttempts  : false,
  needDecisions      : false,
  needUsers          : false,
  needPossibleAgents : false,
  letThrough         : false,
  loadWhenInactive   : false,
}

const requiresPacy = (givenConfig:LoaderConfig={}) => Component => props => {
  const config = {...baseConfig, ...givenConfig}
  return (
    <PacyLoaderConnected
      defaultStatsNeeded={config.defaultStatsNeeded}
      needStats={config.needStats || false}
      needSpendAttempts={config.needSpendAttempts || false}
      needDecisions={config.needDecisions || false}
      needUsers={config.needUsers || false}
      needPossibleAgents={config.needPossibleAgents || false}
      letThrough={config.letThrough || false}
      loadWhenInactive={config.loadWhenInactive || false}
      render={ (account, requestReportingDataLoad, loading) => <Component account={account} requestReportingDataLoad={requestReportingDataLoad} loadingPacy={loading} {...props} /> }
    />
  )
}

export default requiresPacy
