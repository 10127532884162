import moment              from "moment"
import {
  Proxy,
  AgentCompatibleProvider,
  AgentCompatibleChannel,
  Agent,
  PossibleAgent
}                          from "./"
import {
  Guideline,
  getGuidelineForDay,
  isVagueGuideline,
  isPreciseGuideline
}                          from "./Guideline"
import {StringMap}         from "../generics"
import ArrayUtils          from "../../utils/ArrayUtils"

export interface ProxyGroup {
  id             : string
  accountId      : number
  channel        : ProxyGroupPossibleChannel
  name           : string
  proxies        : Proxy[]
  guidelines     : Guideline[]
  createdOn      : string
  lastModifiedOn : string
  lastModifiedBy : number
  sharing        : boolean
  description    : string
}

export enum ProxyGroupPossibleChannel {
  "PERFORMANCE_MAX" = "PERFORMANCE_MAX",
  "SEARCH"  = "SEARCH",
  "SOCIAL"  = "SOCIAL",
  "UNDEFINED" = "UNDEFINED",
  "MIXED"   = "MIXED",
}

export type ProxyGroupsToAgentsTree = StringMap<StringMap<StringMap<AgentCompatibleProvider>>>

/********* UTILS *********/
export const isSettingGuidelineOnInactiveProxy = (proxyGroup:ProxyGroup):boolean => {
  const currentGuideline = getGuidelineForDay(proxyGroup.guidelines, moment().format("YYYY-MM-DD"))
  if(isVagueGuideline(currentGuideline)){
    return proxyGroup.proxies.every((proxy)=>!proxy.active)
  }
  for(const proxyId of Object.keys(currentGuideline.amounts)){
    if(currentGuideline.amounts[proxyId] === 0){continue}
    const proxy = proxyGroup.proxies.find(x=>x.id === proxyId)
    if(!proxy || !proxy.active){return true}
  }
  return false
}

export const getActiveProvidersFromProxyGroup = (proxyGroup:ProxyGroup):Set<AgentCompatibleProvider> => {
  let providers = new Set<AgentCompatibleProvider>()
  const guideline = getGuidelineForDay(proxyGroup.guidelines, moment().format("YYYY-MM-DD"))
  for(const proxy of proxyGroup.proxies){
    getActiveProvidersFromProxy(proxy, guideline, providers).forEach(provider=>providers.add(provider))
  }
  return providers
}

const getActiveProvidersFromProxy = (proxy:Proxy, guideline:Guideline, providers:Set<AgentCompatibleProvider>):Set<AgentCompatibleProvider> => {
  if(
    proxy.active && (
      (isVagueGuideline(guideline) && guideline.amount > 0 && proxy.weight > 0) ||
      (isPreciseGuideline(guideline) && guideline.amounts[proxy.id])
    )
  ){
    for(const agent of proxy.agents){
      getActiveProvidersFromAgent(agent, providers).forEach(provider=>providers.add(provider))
    }
  }
  return providers
}

const getActiveProvidersFromAgent = (agent:Agent, providers:Set<AgentCompatibleProvider>):Set<AgentCompatibleProvider> => {
  if(agent.active && agent.weight){
    providers.add(agent.provider)
  }
  return providers
}

export const getProxyGroupsToAgentsTreeForProviders = (providers:AgentCompatibleProvider[], proxyGroups:ProxyGroup[]):ProxyGroupsToAgentsTree => {
  let tree:ProxyGroupsToAgentsTree = {}
  for(const proxyGroup of proxyGroups){
    const guideline = getGuidelineForDay(proxyGroup.guidelines, moment().format("YYYY-MM-DD"))
    for(const proxy of proxyGroup.proxies){
      geTreeFromProxy(proxy, guideline, providers, tree, proxyGroup.id)
    }
  }
  return tree
}

const geTreeFromProxy = (proxy:Proxy, guideline:Guideline, providers:AgentCompatibleProvider[], tree:ProxyGroupsToAgentsTree, proxyGroupId:string):ProxyGroupsToAgentsTree => {
  if(
    proxy.active && (
      (isVagueGuideline(guideline) && guideline.amount > 0 && proxy.weight > 0) ||
      (isPreciseGuideline(guideline) && guideline.amounts[proxy.id])
    )
  ){
    for(const agent of proxy.agents){
      getTreeFromAgent(agent, providers, tree, proxyGroupId, proxy.id)
    }
  }
  return tree
}

const getTreeFromAgent = (agent:Agent, providers:AgentCompatibleProvider[], tree:ProxyGroupsToAgentsTree, proxyGroupId:string, proxyId:string):ProxyGroupsToAgentsTree => {
  if(agent.active && agent.weight && ArrayUtils.contain(providers, agent.provider)){
    if(!tree[proxyGroupId]){
      tree[proxyGroupId] = {}
    }
    if(!tree[proxyGroupId][proxyId]){
      tree[proxyGroupId][proxyId] = {}
    }
    tree[proxyGroupId][proxyId][agent.id] = agent.provider
  }
  return tree
}

export const filterProxyGroupsToProviderChannel = (proxyGroups:ProxyGroup[], provider:AgentCompatibleProvider, possibleAgents:PossibleAgent[]):ProxyGroup[] => {
  const possibleAgentsChannels = new Set<AgentCompatibleChannel>()
  possibleAgents.forEach((x)=>{
    possibleAgentsChannels.add(x.channel)
  })
  return proxyGroups.filter((x)=>{
    switch (provider) {
      case AgentCompatibleProvider.AW:
        return x.channel === ProxyGroupPossibleChannel.SEARCH && possibleAgentsChannels.has(AgentCompatibleChannel.SEARCH) ||
          x.channel === ProxyGroupPossibleChannel.PERFORMANCE_MAX && possibleAgentsChannels.has(AgentCompatibleChannel.PERFORMANCE_MAX)
      case AgentCompatibleProvider.BING:
        return x.channel === ProxyGroupPossibleChannel.SEARCH
      case AgentCompatibleProvider.FACEBOOK:
        return x.channel === ProxyGroupPossibleChannel.SOCIAL
      default:
        return false
  }})
}
