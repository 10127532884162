import "./ProxyGroup.css"
import * as React                          from "react"
import moment                              from "moment"
import {connect}                           from "react-redux"
import DateUtils                           from "../../../../utils/DateUtils"
import {addGuideline}                      from "../../../../actions/pacy/Guideline"
import PacyAccessors                       from "../../../../storeAccessor/Pacy"
import { ProxyGroup }                      from "../../../../model/pacy/ProxyGroup"
import {
  Guideline,
  createTempGuideline,
}                                          from "../../../../model/pacy/Guideline"
import BudgetStats                         from "../../../../model/Store/Statistics/BudgetStats"
import {StringMap}                         from "../../../../model/generics"
import Period                              from "../../../../model/Period"
import GuidelinesList                      from "./GuidelinesList"
import ProxyGroupSummaryChart              from "./ProxyGroupSummaryChart"
import PreciseGuidelineSetup               from "./PreciseGuidelineSetup/"
import ArrowButton                         from "../../../../components/Button/ArrowButton"
import Loading                             from "../../../../components/loading/"
import {PacyV0UsageAlert, displayV0Alert}  from "../../../../model/alerting/PacyV0UsageAlert"
import {AlertType}                         from "../../../../model/constant/AlertType"
import PermissionsUtils                    from "../../../../utils/PermissionsUtils"
import {UserPermissions}                   from "../../../../model/Store/Login/UserLogged"
import UserConnected                       from "../../../../model/Store/Login/UserConnected"
import Permissions                         from "../../../../model/constant/Permissions"
import { Typography }                      from '@material-ui/core'
import { Translate }                       from 'react-localize-redux'

interface ProxyGroupOwnProps{
  proxyGroup               : ProxyGroup
  requestReportingDataLoad : (period:Period)=>boolean
  budgetStatsByMonth       : StringMap<BudgetStats[]>
  color                   ?: string
  readOnly                ?: boolean
  noGraph                 ?: boolean
  addGuideline            ?: (guideline:Guideline) => void
  hideVagueConfirmModal   ?: boolean
}
interface ProxyGroupProps extends ProxyGroupOwnProps{
  savingGuideline : boolean
  addGuideline    : (guideline:Guideline)=>void
  alert           : PacyV0UsageAlert
  accountId       : number,
  permissions     : UserPermissions[],
  profile         : UserConnected,
}
const makeMapStateToProps = () => {
  const savingSelector = PacyAccessors.makeIsGuidelineSavingForProxyGroup()
  return (state,ownProps:ProxyGroupOwnProps)=>{
    return {
      savingGuideline    : savingSelector(state, ownProps),
      alert              : state.Alerts.Retrieved[AlertType.PACY_V0USAGE] || {},
      accountId          : state.Accounts.selected,
      permissions        : state.Login.userLogged.permissions,
      profile            : state.Login.userLogged.profile,
    }
  }
}
const mapDispatchToProps = {
  addGuideline
}
const mergeProps = (SP,DP,ownProps:ProxyGroupOwnProps)=>{
  const defaultAddGuideline = (guideline:Guideline)=>DP.addGuideline(ownProps.proxyGroup.accountId, ownProps.proxyGroup.id, guideline)
  return {
    ...SP,...DP,...ownProps,
    addGuideline : ownProps.addGuideline || defaultAddGuideline
  }
}
interface GuidelinesListState{
  middleMonth : string
  tableOpened : boolean //save table state between saving
}

class ProxyGroupComponent extends React.Component<ProxyGroupProps,GuidelinesListState>{
  constructor(props:ProxyGroupProps){
    super(props)
    this.state = {
      middleMonth : moment().format(DateUtils.YMFormat),
      tableOpened : false,
    }
    this.previous = this.previous.bind(this)
    this.next = this.next.bind(this)
    this.setGuideline = this.setGuideline.bind(this)
    this.renderContent = this.renderContent.bind(this)
  }
  previous(){
    this.setState(prevState=>({
      middleMonth : DateUtils.navigateYM(prevState.middleMonth, -1)
    }))
  }
  next(){
    this.setState(prevState=>({
      middleMonth : DateUtils.navigateYM(prevState.middleMonth, 1)
    }))
  }
  setGuideline(year:string, month:string, amount:number|StringMap<number>){
    this.props.addGuideline(createTempGuideline(year, month, amount))
  }
  render(){
    return (
      <div className={"ProxyGroup " + (this.props.noGraph ? "WithoutGraph" : "")}>
        <div className="Header">
          <div className="Title">
            <Typography className="Channel" variant="h6" color="primary">
              <Translate id={`pacyConfigs.channels.${this.props.proxyGroup.channel}`} />
            </Typography>
            <Typography className="Name" variant="h6" >{this.props.proxyGroup.name}</Typography>
          </div>
          {/*isSettingGuidelineOnInactiveProxy(this.props.proxyGroup) && "*"*/}
        </div>
        {this.props.savingGuideline
          ? <div className="Spinner"><Loading translateId="loadings.saveGuideline"/></div>
          : this.renderContent()
        }
      </div>
    )
  }
  renderContent(){
    const shownMonths = [
      DateUtils.navigateYM(this.state.middleMonth, -2),
      DateUtils.navigateYM(this.state.middleMonth, -1),
      this.state.middleMonth,
      DateUtils.navigateYM(this.state.middleMonth, 1),
      DateUtils.navigateYM(this.state.middleMonth, 2),
    ]
    let displayAlert = false
    const permissionsUtils = new PermissionsUtils(this.props.accountId, this.props.permissions, this.props.profile)
    if(permissionsUtils.validatePermissions([Permissions.Pacy_Edit_Config])){
      displayAlert = displayV0Alert(this.props.alert, this.props.proxyGroup)
    }
    return (
      <>
        <div className="Alert" style={displayAlert ? {backgroundColor: '#d3450d'} : {}}></div>
        <div className="Planning" >
          <div className="FatBorder" style={{backgroundColor: this.props.color}}/>
        {/*
          <Icon
            className="SettingsIcon"
            onClick={()=>alert("Settings?")}
            fontSize={"small"}
          >
            settings
          </Icon>
        */}
          <ArrowButton onClick={this.previous} orientation="left"/>
          <GuidelinesList
            proxyGroup={this.props.proxyGroup}
            shownMonths={shownMonths}
            color={this.props.color}
            budgetStatsByMonth={this.props.budgetStatsByMonth}
            setGuideline={this.setGuideline}
            readOnly={this.props.readOnly}
            showConfirmModal={!this.props.hideVagueConfirmModal}
          />
          <ArrowButton onClick={this.next} orientation="right"/>
        </div>
        {!this.props.noGraph && <div className="RightChart">
          <div className="FatBorder" style={{backgroundColor: this.props.color}}/>
          <ProxyGroupSummaryChart
            proxyGroup={this.props.proxyGroup}
            shownMonths={shownMonths}
            requestReportingDataLoad={this.props.requestReportingDataLoad}
            guidelineColor={this.props.color}
            budgetStatsByMonth={this.props.budgetStatsByMonth}
          />
        </div>}
        <PreciseGuidelineSetup
          proxyGroup={this.props.proxyGroup}
          color={this.props.color}
          shownMonths={shownMonths}
          defaultOpen={this.state.tableOpened}
          onOpenChange={(opened)=>this.setState({tableOpened: opened})}
          setGuideline={this.setGuideline}
          readOnly={this.props.readOnly}
        />
      </>
    )
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps, mergeProps)(ProxyGroupComponent) as React.ComponentType<ProxyGroupOwnProps>
