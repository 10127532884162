import * as React          from "react"
import {withRouter}        from "react-router"
import {
  withLocalize,
  TranslateFunction,
  Translate
}                          from "react-localize-redux"
import styles              from './PacingRatioConfigDisplayStyles'
import {
  Card,
  CardContent,
  Button,
  Typography,
  Snackbar,
  IconButton
}                          from "@material-ui/core"
import CloseIcon           from '@material-ui/icons/Close';
import FormControlLabel    from '@material-ui/core/FormControlLabel'
import RadioGroup          from '@material-ui/core/RadioGroup'
import Radio               from '@material-ui/core/Radio'
import Input               from '@material-ui/core/Input'
import InputAdornment      from '@material-ui/core/InputAdornment'
import {PacingRatioConfig} from "../../../../model/pacy/PacingRatioConfig"
import {Panel}             from "../../../../components/Panel/Panel"
import RoutingUtils        from "../../../../utils/RoutingUtils"
import {Modal}             from "carbon-components-react"
import Loading             from "../../../../components/loading/"

interface PacingRatioConfigDisplayOwnProps {
  accountId : number
  pacingRatioConfig : PacingRatioConfig
  updatePacingRatio : (accountId:number, pacingRatioConfig:PacingRatioConfig) => void
  isSaving : boolean
  isRetrievingAccount : boolean
  clearPacyRatioError : () => void
  error : string
  shouldBlockNavigation : boolean
  setShouldBlockNavigation : (shouldBlock:boolean, title:string, content:string) => void
}

interface PacingRatioConfigDisplayInternalProps extends PacingRatioConfigDisplayOwnProps{
  translate: TranslateFunction
  history
}

export const RATIO_HIGH = 103
export const RATIO_MAX = 105
export const RATIO_MIN = 100

const PacingRatioConfigDisplay = (props:PacingRatioConfigDisplayInternalProps) => {
  const MESSAGE_WARNING_HIGH = props.translate("pacingRatio.setup.messages.warningHigh") as string
  const MESSAGE_ERROR_HIGH = props.translate("pacingRatio.setup.messages.errorHigh") as string
  const MESSAGE_ERROR_LOW = props.translate("pacingRatio.setup.messages.errorLow") as string
  const NO_MESSAGE = props.translate("common.NOTHING") as string
  const MESSAGE_NOT_SAVED = "pacingRatio.setup.messages.configChanged"
  const WARNING = "common.warning"

  const [newPacingRatioConfig, setNewPacingRatioConfig] = React.useState<PacingRatioConfig>(props.pacingRatioConfig)
  const [canSave, setCanSave] = React.useState<boolean>(false)
  const [messageAll, setMessageAll] = React.useState<string>(NO_MESSAGE)
  const [messageChannels, setMessageChannels] = React.useState<string>(NO_MESSAGE)
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [savedSnackOpen, setSavedSnackOpen] = React.useState<boolean>(props.isSaving)

  const [isModified, setIsModified] = React.useState(false)

  React.useEffect(()=>{
    if (props.isRetrievingAccount){
      setSavedSnackOpen(props.isRetrievingAccount)
    }
  }, [props.isRetrievingAccount])

  React.useEffect(() => {
    setCanSave(configChanged)
    setHasError(props.error !== undefined)
    setMessageAll(validateValues([newPacingRatioConfig.all]))
    setMessageChannels(validateValues([newPacingRatioConfig.pmax, newPacingRatioConfig.search, newPacingRatioConfig.social]))
    shouldBlockNavigation()
  })

  const handleAllActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPacingRatioConfig(prevState => {
      return {
        ...prevState,
        allActive : event.target.value == 'true' ? true : false,
        all : props.pacingRatioConfig.all,
        pmax : props.pacingRatioConfig.pmax,
        search : props.pacingRatioConfig.search,
        social : props.pacingRatioConfig.social,
      }
    })
  }

  const handlePacingRatioChange = (prop: keyof PacingRatioConfig) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPacingRatioConfig({
      ...newPacingRatioConfig,
      [prop] : Number(event.target.value)
    })
  }

  const handleSave = () => {
    props.updatePacingRatio(props.accountId, newPacingRatioConfig)
  }

  const handleCancel = () => {
    if(configChanged()) {
      console.log(props.translate(MESSAGE_NOT_SAVED))
    }
    props.setShouldBlockNavigation(false, undefined, undefined)
    RoutingUtils.replaceLastElementOfUrl(props.history, "Planner")
  }

  const configChanged = () : boolean => {
    let stringInitialConfig = JSON.stringify(props.pacingRatioConfig)
    let stringNewConfig = JSON.stringify(newPacingRatioConfig)
    return stringInitialConfig !== stringNewConfig
  }

  const shouldBlockNavigation = () : void => {
    const newModifiedState = configChanged()
    if (newModifiedState !== props.shouldBlockNavigation && (newModifiedState || (isModified && !newModifiedState))){
      props.setShouldBlockNavigation(newModifiedState, WARNING, MESSAGE_NOT_SAVED)
    }
    if(newModifiedState !== isModified){
      setIsModified(newModifiedState)
    }
  }

  const validateValues = (values : Array<number>) : string => {
    let finalMessage = NO_MESSAGE
    let messages : Array<string> = []
    values.forEach(value => {
      messages.push(validateValue(value))
    })
    if (messages.indexOf(MESSAGE_WARNING_HIGH) > -1) {
      finalMessage = MESSAGE_WARNING_HIGH
    }
    if (messages.indexOf(MESSAGE_ERROR_LOW) > -1) {
      finalMessage = MESSAGE_ERROR_LOW
      setCanSave(false)
    }
    if (messages.indexOf(MESSAGE_ERROR_HIGH) > -1) {
      finalMessage = MESSAGE_ERROR_HIGH
      setCanSave(false)
    }
    return finalMessage
  }
  const validateValue = (value : number) : string => {
    let message = NO_MESSAGE
    if (value >= RATIO_HIGH && value <= RATIO_MAX) {
      message = MESSAGE_WARNING_HIGH
    }
    if (value > RATIO_MAX) {
      message = MESSAGE_ERROR_HIGH
    }
    if (value < RATIO_MIN) {
      message = MESSAGE_ERROR_LOW
    }
    return message
  }

  const allowDigitsOnly = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const aCode = event.which ? event.which : event.keyCode
    if (aCode > 31 && (aCode < 48 || aCode > 57)){
      event.preventDefault()
    }
  }

  const closeModal = () : void => {
    props.clearPacyRatioError()
  }

  const classes = styles()

  if (props.isSaving) {
    return <div className="Spinner"><Loading translateId="loadings.pacingRatio"/></div>
  } else {
    return (
    <>
      <Snackbar
        open={savedSnackOpen}
        autoHideDuration={5000}
        onClose={()=> {setSavedSnackOpen(false)}}
        anchorOrigin={{vertical:"top", horizontal:"center"}}
        message={props.translate("pacingRatio.setup.messages.saved") as string}
      >
        <div className={classes.alert}>
          {props.translate("pacingRatio.setup.messages.saved")}
          <IconButton className={classes.buttonSnackbar} size="small" aria-label="close" color="inherit" onClick={() => {setSavedSnackOpen(false)}}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </Snackbar>
      <Panel title={props.translate("pacingRatio.setup.title") as string} >
        <div className={classes.panel}>
          <RadioGroup aria-label="pacingRatio" name="pacingRatio" value={newPacingRatioConfig.allActive ? 'true' : 'false'} onChange={handleAllActiveChange}>
          <Card className={`${classes.card} ${!newPacingRatioConfig.allActive ? classes.cardInactive : ''}`}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.category}>
                    <FormControlLabel value='true' control={<Radio color="default" />} label={<Typography className={classes.categoryTitle}>{props.translate("pacingRatio.setup.all")}</Typography>} />
                  </div>
                  <div className={classes.row}>
                    <label className={classes.label} htmlFor="inputAll">{props.translate("pacingRatio.setup.total")}</label>
                    <Input
                      id="inputAll"
                      className={classes.inputPacingRatio}
                      value={newPacingRatioConfig.all}
                      inputProps={{
                        maxLength:'3',
                      }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      onKeyPress={allowDigitsOnly}
                      onChange={handlePacingRatioChange("all")}
                      disabled={!newPacingRatioConfig.allActive}
                    />
                  </div>
                  <div className={classes.messageRow}>
                    <Typography className={classes.message}>{messageAll}</Typography>
                  </div>
                </CardContent>
              </Card>
              <Card className={`${classes.card} ${newPacingRatioConfig.allActive ? classes.cardInactive : ''}`}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.category}>
                    <FormControlLabel value='false' control={<Radio color="default" />} label={<Typography className={classes.categoryTitle}>{props.translate("pacingRatio.setup.byChannels")}</Typography>} />
                  </div>
                  <div className={classes.row}>
                    <label className={classes.label} htmlFor="inputPmax">{props.translate("pacingRatio.setup.pmax")}</label>
                    <Input
                      id="inputPmax"
                      className={classes.inputPacingRatio}
                      value={newPacingRatioConfig.pmax}
                      inputProps={{
                        maxLength:'3',
                      }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      onKeyPress={allowDigitsOnly}
                      onChange={handlePacingRatioChange("pmax")}
                      disabled={newPacingRatioConfig.allActive}
                    />
                  </div>
                  <div className={classes.row}>
                    <label className={classes.label} htmlFor="inputSearch">{props.translate("pacingRatio.setup.search")}</label>
                    <Input
                      id="inputSearch"
                      className={classes.inputPacingRatio}
                      value={newPacingRatioConfig.search}
                      inputProps={{
                        maxLength:'3',
                      }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      onKeyPress={allowDigitsOnly}
                      onChange={handlePacingRatioChange("search")}
                      disabled={newPacingRatioConfig.allActive}
                    />
                  </div>
                  <div className={classes.row}>
                    <label className={classes.label} htmlFor="inputSocial">{props.translate("pacingRatio.setup.social")}</label>
                    <Input
                      id="inputSocial"
                      className={classes.inputPacingRatio}
                      value={newPacingRatioConfig.social}
                      inputProps={{
                        maxLength:'3',
                      }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      onKeyPress={allowDigitsOnly}
                      onChange={handlePacingRatioChange("social")}
                      disabled={newPacingRatioConfig.allActive}
                    />
                  </div>
                  <div className={classes.messageRow}>
                    <Typography className={classes.message}>{messageChannels}</Typography>
                  </div>
                </CardContent>
              </Card>
          </RadioGroup>
        </div>
      </Panel>
      <div className={classes.containerButtons}>
        <Button
          className={classes.buttonSave}
          style={{color:'white'}}
          variant="contained"
          size="large"
          onClick={handleSave}
          disabled={!canSave}
        >
          <Translate id="common.save" />
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          onClick={handleCancel}
        >
          <Translate id="common.cancel" />
        </Button>
      </div>
      <div>
        <Modal
          open={hasError}
          passiveModal
          modalHeading={props.translate("pacingRatio.setup.modal.title")}
          onRequestClose={closeModal}
        >
          <div>
            <h5>
              {props.translate("pacingRatio.setup.modal.message")} :
            </h5>
            <br />
            <h5 style={{color:'red'}}>{props.error}</h5>
            <br />
          </div>
          <Button onClick={closeModal}>Ok</Button>
        </Modal>
      </div>
    </>
    )
  }
}

export default (withRouter(
  withLocalize(PacingRatioConfigDisplay as any) as any
)) as React.ComponentType<PacingRatioConfigDisplayOwnProps>
