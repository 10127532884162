import Waze             from "../utils/Waze"
import Actions          from "../model/constant/actions"
import DateUtils        from "../utils/DateUtils"
import FVDUtils         from "../utils/FieldsValuesDefinitionUtils"
import Period           from "../model/Period"
import {getToken}       from "../storeAccessor/Generic"

const NO_ACCOUNT_STATUS = "NO_WAZE_ACCOUNT_LINKED"
const loadWazePerfoDataSuccess = (response, accountId) => {
  if(response.data.status == NO_ACCOUNT_STATUS){
    return {
      type : Actions.RETRIEVE_WAZE_DATA_NO_ACCOUNT,
      accountId : accountId
    }
  }
  return {
    type      : Actions.RETRIEVE_WAZE_PERFORMANCE_DATA,
    rows      : FVDUtils.decompress(response.data.reporting_data),
    accountId : accountId
  }
}
export const loadWazePerfoData = (accountId:number, period:Period) => (dispatch, getState) => {
  dispatch({type:Actions.RETRIEVING_WAZE_PERFORMANCE_DATA, status:true})
  Waze.get({
      action      : "GetWazeData",
      token       : getToken(getState()),
      accountId   : accountId,
      periodStart : DateUtils.format(period.dateFrom,"%Y-%m-%d"),
      periodEnd   : DateUtils.format(period.dateTo,"%Y-%m-%d"),
    },
    response => {
      dispatch(loadWazePerfoDataSuccess(response, accountId)),
      dispatch({type:Actions.RETRIEVING_WAZE_PERFORMANCE_DATA, status:false})
    },
    error => console.log(error)
  )
}
export const retrieveCompanies = () => (dispatch, getState) => {
  dispatch({type : Actions.RETRIEVING_WAZE_COMPANIES})
  Waze.get({
      action : "GetWazeCompanies",
      token  : getToken(getState())
    },
    response=>dispatch({
      type      : Actions.RETRIEVE_WAZE_COMPANIES,
      companies : response?.data?.companies || []
    }),
    error=>console.log(error)
  )
}
