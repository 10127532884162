import './index.css'
import * as React                             from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Icon,
  Divider,
  Switch,
}                                             from '@material-ui/core'
import {
  ProxyGroup,
  ProxyGroupPossibleChannel,
  filterProxyGroupsToProviderChannel
}                                             from '../../../../../../model/pacy/ProxyGroup'
import {
  Agent,
  AgentCompatibleProvider,
  AgentCompatibleChannel,
  PossibleAgent,
  filterPossibleAgentsToUnused,
  filterPossibleAgentsToProviderChannel,
  filterPossibleAgentsToAvailableProxyGroupsChannels
}                                             from '../../../../../../model/pacy'
import CreateAgentParams                      from './CreateAgentParams'
import AgentWeightsConfiguration              from '../../AgentWeightsConfiguration'
import {
  AccountModifier,
  createAddAgentAccountModifier,
  createToggleAgentAccountModifier,
  createSetAgentWeightAccountModifier,
  generate
}                                             from '../../../../../../model/pacy/AccountModifiers'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                             from 'react-localize-redux'
import CreateAgentStep1                       from './CreateAgentStep1'
import CreateAgentStep2                       from './CreateAgentStep2'

interface CreateAgentOwnProps {
  proxyGroups          ?: ProxyGroup[]
  selectedProxyGroupId ?: string
  selectedProxyGroupChannel ?: ProxyGroupPossibleChannel
  selectedProxyId      ?: string
  possibleAgents        : PossibleAgent[]
  handleCloseModal      : () => void
  addAccountModifiers   : (newActions:AccountModifier[]) => void
}
interface CreateAgentProps extends CreateAgentOwnProps {
  translate : TranslateFunction
}

const defaultEmptyAgent = ()=>({
  id            : generate(),
  active        : true,
  channel       : AgentCompatibleChannel.UNDEFINED, // This will be replaced by Step 2
  name          : '',
  provider      : AgentCompatibleProvider.AW, // This will be replaced by Step 1
  providerId    : "",
  maxSharing    : 0,
  weight        : 0,
  lastUpdatedOn : '',
  spendAttempts : []
})

const CreateAgent = (props:CreateAgentProps) => {
  const [step,setStep] = React.useState<number>(1)
  const [selectedProxyGroupId,setSelectedProxyGroupId] = React.useState<string>(props.selectedProxyGroupId || "")
  const [selectedProxyGroupChannel,setSelectedProxyGroupChannel] = React.useState<ProxyGroupPossibleChannel>(props.selectedProxyGroupChannel || ProxyGroupPossibleChannel.UNDEFINED)
  const [selectedProxyId,setSelectedProxyId] = React.useState<string>(props.selectedProxyId || "")
  const [newAgent,setNewAgent] = React.useState<Agent>(defaultEmptyAgent())
  const handleNameChange = (name:string) => {
    if(name.length <= 128) {
      setNewAgent(prevState=>({
        ...prevState, name
      }))
    }
  }
  const onSave = (agentsChanged:{[id:string]:number},agentsActivated:string[]) => {
    const agentsToActivate = agentsActivated.map(id=>(
      createToggleAgentAccountModifier(id, true, selectedProxyId, selectedProxyGroupId)
    ))
    const weightsChanged = Object.keys(agentsChanged).map(id=>(
      createSetAgentWeightAccountModifier(id, agentsChanged[id], selectedProxyId, selectedProxyGroupId)
    ))
    props.addAccountModifiers([createAddAgentAccountModifier(selectedProxyGroupId, selectedProxyId, {
      id:newAgent.id,
      channel:newAgent.channel,
      name:newAgent.name,
      provider:newAgent.provider,
      providerId:newAgent.providerId,
      weight:newAgent.weight,
      active:newAgent.active,
      maxSharing:newAgent.maxSharing,
    }),...agentsToActivate,...weightsChanged])
    props.handleCloseModal()
  }

  const unusedPossibleAgents = filterPossibleAgentsToAvailableProxyGroupsChannels(filterPossibleAgentsToUnused(props.possibleAgents, props.proxyGroups), props.proxyGroups)
  const providerChannelPossibleAgents = filterPossibleAgentsToProviderChannel(unusedPossibleAgents, newAgent.provider, selectedProxyGroupChannel)
  if(step === 1){
    return (
      <CreateAgentStep1
        possibleAgents={unusedPossibleAgents}
        proxyGroupChannel={selectedProxyGroupChannel}
        selectProvider={(provider)=>{
          setNewAgent(prevState=>({...prevState, provider}))
          setStep(2)
        }}
        onCancel={props.handleCloseModal}
      />
    )
  }
  if(step === 2) {
    return (
      <CreateAgentStep2
        agent={newAgent}
        proxyGroups={filterProxyGroupsToProviderChannel(props.proxyGroups, newAgent.provider, providerChannelPossibleAgents)}
        possibleAgents={providerChannelPossibleAgents}
        selectedProxyGroupId={selectedProxyGroupId}
        selectedProxyId={selectedProxyId}
        setActive={(event) => setNewAgent(prevState=>({...prevState,active:event.target.checked}))}
        setMaxSharing={(newValue) => setNewAgent(prevState=>({...prevState,maxSharing:newValue/100}))}
        setProviderId={(providerId)=>setNewAgent(prevState=>({...prevState, providerId}))}
        setChannel={(channel)=>setNewAgent(prevState=>({...prevState, channel}))}
        setName={handleNameChange}
        selectProxyGroup={(event)=>{
          setSelectedProxyGroupId(event.target.value)
          setSelectedProxyGroupChannel(props.proxyGroups.find(proxyGroup=>proxyGroup.id===event.target.value).channel)
          if(selectedProxyId){setSelectedProxyId("")} // If the proxy group is changed, the proxy should be selected again
        }}
        selectProxy={(event) => setSelectedProxyId(event.target.value)}
        onCancel={()=>{
          setSelectedProxyId(props.selectedProxyId||"")
          setSelectedProxyGroupId(props.selectedProxyGroupId||"")
          setSelectedProxyGroupChannel(props.selectedProxyGroupChannel||ProxyGroupPossibleChannel.UNDEFINED)
          setNewAgent(defaultEmptyAgent())
          setStep(1)
        }}
        onConfirm={()=>setStep(3)}
      />
    )
  }
  return (
    <AgentWeightsConfiguration
      agents={[newAgent,...props.proxyGroups.find(proxyGroup=>proxyGroup.id===selectedProxyGroupId).proxies.find(proxy=>proxy.id===selectedProxyId).agents]}
      handleCloseModal={()=>setStep(2)}
      handleWeightsChange={onSave}
    />
  )
}

 export default withLocalize(CreateAgent as any) as React.ComponentType<CreateAgentOwnProps>
