import BUTTONS              from "../model/constant/menuButtons"
import { DeviceStatistics } from "../model/constant/DeviceStatistics"
import { DetailsOptions }   from "../model/constant/BudgetsInsightDetailsOptions"
import GeoTargetingTypes    from "../model/constant/GeoTargetingTypes"
import {
  ProxyGroupPossibleChannel
}                           from "../model/pacy/ProxyGroup"

//ALWAYS english then french

const translations = {
    'pacyConfigs' : {
        'agents' : {
          'google' : [
            "This agent is powered by Google Ads",
            "Cet agent est alimenté par Google Ads"
          ],
          'bing' : [
            "This agent is powered by Microsoft Advertising",
            "Cet agent est alimenté par Microsoft Advertising"
          ],
          'facebook' : [
            "This agent is powered by Facebook Ads",
            "Cet agent est alimenté par Facebook Ads"
          ],
        },
	      'boost': [
          "Boost",
          "Renforcer"
        ],
        'configureWeights': [
          "Configure Weights",
          "Configurer les Poids"
        ],
        'flat' : [
          "Flat",
          "Plate"
        ],
        'linear' : [
          "Linear",
          "Linéaire"
        ],
        'done' : [
          "Done",
          "Terminer"
        ],
        'advancedConfig' : [
          "Advanced configuration",
          "Configuration avancée"
        ],
        'budgetGroup' : [
          "Budget group",
          "Groupe de budget"
        ],
        'createProxyGroup' : [
          "New budget group",
          "Nouveau groupe de budget"
        ],
        'createProxy' : [
          "New budget",
          "Nouveau budget"
        ],
        'createAgent' : [
          "New agent",
          "Nouvel agent"
        ],
        'defineChannel' : [
          "Please first define the budget group channel",
          "Veuillez d'abord définir le canal du groupe de budget"
        ],
        'sameChannel' : [
          "Only agents from the same channel type can be added under a single budget group",
          "Seuls les agents du même type de canal peuvent être ajoutés sous un seul groupe de budget"
        ],
        'name' : [
          "Name",
          "Nom"
        ],
        'description' : [
          "Description (optional)",
          "Description (optionnel)"
        ],
        'sharingHint' : [
          "Toggling sharing will determine if the budgets inside this budget group share between them",
          "Activer / désactiver le partage déterminera si les budgets de ce groupe budgétaire se partagent entre eux"
        ],
        'active' : [
          "Active",
          "Actif"
        ],
        'selectProxyGroup' : [
          "Choose a budget group",
          "Choisir un groupe de budget"
        ],
        'selectProxy' : [
          "Choose a budget",
          "Choisir un budget"
        ],
        'selectPossibleAgentAW' : [
          "Choose a Google budget",
          "Choisir un budget Google"
        ],
        'selectPossibleAgentBING' : [
          "Choose a Bing budget",
          "Choisir un budget Bing"
        ],
        'selectPossibleAgentFACEBOOK' : [
          "Choose a Facebook campaign",
          "Choisir une campagne Facebook"
        ],
        'selectProvider' : [
          "Choose a provider",
          "Choisir un fournisseur"
        ],
        'channel' : [
          "Channel",
          "Canal"
        ],
        'selectChannel' : [
          "Choose a channel",
          "Choisir un canal"
        ],
        'possibleAgentsCount' : [
          "${count} available agents",
          "${count} agents disponibles"
        ],
        'sharing' : [
          "Sharing",
          "Partage"
        ],
        'configure' : [
          "Configure",
          "Configurer"
        ],
        'amounts' : [
          "Amounts",
          "Montants"
        ],
        'weightsConfig' : [
          "Weights configuration",
          "Configuration des poids"
        ],
        'move' : [
          "Move",
          "Déplacer"
        ],
        'moveProxy' : [
          "Move budget",
          "Déplacer le budget"
        ],
        'chooseProxyGroupToMoveTo' : [
          "Select a budget group to move to",
          "Choisir le groupe de budget de destination "
        ],
        'searchProxyGroup' : [
          "Budget group..",
          "Groupe de budget.."
        ],
        'moveInvestmentQuestion' : [
          "Would you like to move investment ?",
          "Voulez vous déplacer l'investissement ?"
        ],
        'movingInvestment' : [
          "With moving investment",
          "Avec déplacement de l'investissement"
        ],
        'notMovingInvestment' : [
          "Without moving investment",
          "Sans déplacement de l'investissement"
        ],
        'activate' : [
          "Activate",
          "Activer"
        ],
        'agentsWeights' : [
          "Shared budgets weights",
          "Poids des budgets partagés"
        ],
        'boostConfiguration' : [
          "Boost configuration",
          "Configuration du Boost"
        ],
        'status' : [
          "Status",
          "Statut"
        ],
        'boostDuration' : [
          "Duration (days)",
          "Durée (jours)"
        ],
        'boostPercentage' : [
          "Boost %",
          "Boost %"
        ],
        'boostShape' : [
          "Shape",
          "Forme"
        ],
        'activateBoost' : [
          "Activate Boost",
          "Activer le Boost"
        ],
        'unspentSharing' : [
          "Share unspent",
          "Partager inutilisé"
        ],
        'weight' : [
          "Weight",
          "Poid"
        ],
        'sharedBudgets' : [
          "Shared budgets",
          "Budgets partagés"
        ],
        'search' : [
          "Search..",
          "Recherche.."
        ],
        'firstPage' : [
          "First page",
          "Première page"
        ],
        'lastPage' : [
          "Last page",
          "Dernière page"
        ],
        'rows' : [
          "Rows",
          "Lignes"
        ],
        'emptyAgentsTable' : [
          "There's no shared budget to display",
          "Aucun budget partagé à afficher"
        ],
        'on' : [
          "on",
          "sur"
        ],
        'accountStateIdConflict' : [
          "The account has been changed while you were on this page, please refresh the page to get the last account state",
          "Le compte a été modifié pendant que vous étiez sur cette page, veuillez actualiser la page pour obtenir le dernier état du compte"
        ],
        'generalError' : [
          "Oops.. Something went wrong. Please refresh the page and try again.",
          "Oups.. Une erreur s'est produite. Veuillez actualiser la page et réessayer."
        ],
        'saveDialog' : [
          "Are you sure you want to save the current changes?",
          "Êtes-vous certain de vouloir sauvegarder les changements actuels?"
        ],
        'pacyEnable' : {
          'toggleButton' : [
            "Pacy Enabled",
            "Pacy Activé"
          ],
          'modal' : {
            'title' : [
              "Are you certain you wish to disable Pacy for this account?",
              "Êtes-vous certain de vouloir désactiver Pacy pour ce compte ?"
            ],
            'message' : [
              "Pacy should not be disabled for any active account.",
              "Pacy ne devrait pas être désactivé sur un compte actif."
            ],
            'instructions' : [
              "Reminder: Please adjust the budgets of this account to $0 before disabling Pacy to prevent potential conflicts with the native platform's settings in the future",
              "Rappel: Veuillez ajuster les budgets de ce compte à 0$ avant de désactiver Pacy pour éviter les conflits potentiels avec les paramètres natifs de la plateforme à l'avenir"
            ],
          },
          'disabledMessage' : [
            "Pacy has been disabled for this account",
            "Pacy a été désactivé pour ce compte"
          ]
        },
        'pacyV0Alert' : [
          "Issue with provider(s) detected",
          "Problème avec le(s) provider(s) détecté"
        ],
        'channels' : {
          [ProxyGroupPossibleChannel.PERFORMANCE_MAX] : [
            "Performance Max",
            "Performance Max"
          ],
          [ProxyGroupPossibleChannel.SEARCH] : [
            "Search",
            "Search"
          ],
          [ProxyGroupPossibleChannel.SOCIAL] : [
            "Social",
            "Social"
          ],
          [ProxyGroupPossibleChannel.UNDEFINED] : [
            "Undefined",
            "Indéfini"
          ],
          [ProxyGroupPossibleChannel.MIXED] : [
            "Mixed",
            "Mixte"
          ],
        },
    },
    'pacingRatio' : {
      'title' : [
        "Pacing ratio",
        "Pacing ratio"
      ],
      'setup' : {
        'title' : [
          "Pacing ratio setup",
          "Configuration du pacing ratio"
        ],
        'all' : [
          "All channels",
          "Tous les canaux"
        ],
        'byChannels' : [
          "Pacing by channels",
          "Pacing par canal"
        ],
        'pmax' : [
          "Performance Max",
          "Performance Max"
        ],
        'search' : [
          "Search",
          "Search"
        ],
        'social' : [
          "Social",
          "Social"
        ],
        'NA' : [
          "NA",
          "ND"
        ],
        'total' : [
          "Total account pacing ratio",
          "Pacing ratio total du compte"
        ],
        'messages' : {
          'configChanged' : [
            "No changes to pacing ratio have been applied! Remember to click Save to apply the changes",
            "Aucun changement au pacing ratio n'a été appliqué. Vous devez cliquer Sauvegarder pour appliquer les changements"
          ],
          'warningHigh' : [
            "Your pacing ratio is very high!",
            "Le pacing ratio est très élevé!"
          ],
          'errorLow' : [
            "Your pacing ratio is very low! A minimum of 100% is required",
            "Le pacing ratio est très bas! Un minimum de 100% est requis"
          ],
          'errorHigh' : [
            "It is not recommended to set a pacing ratio above 105%! Please revise your settings to save",
            "Il n'est pas recommandé de régler le pacing ratio au-delà de 105%! Révisez la configuration pour sauvegarder"
          ],
          'saved' : [
            "Your changes have been saved!",
            "Les changements ont été sauvegardés!"
          ]
        },
        'modal' : {
          'title' : [
            "Error",
            "Erreur"
          ],
          'message' : [
            "An unexpected error occured. Please report the content of this error to technical support",
            "Une erreur inattendue est survenue. Svp, rapportez le contenu de cette erreur au soutien technique"
          ]

        }
      }
    },
    'navBlocker' : {
      'title' : [
        "Leave page without saving ?",
        "Quitter la page sans sauvegarder ?"
      ],
      'message' : [
        "You have unsaved changes. Are you sure you want to leave this page without saving?",
        "Vous avez effectué des changements. Êtes vous certain de vouloir quitter sans sauvegarder?"
      ],
    },
    'alerts' : {
      'notice' : [
        'Notice',
        'Remarquer'
      ]
    },
    'management' : {
      'newUserLevel' : [
        "Choose a new user level for this user: ",
        "Choisissez un nouveau niveau d'utilisateur pour cet utilisateur: "
      ],
      'newWhiteLabel' : [
        "Choose a new white label for this user: ",
        "Choisissez un nouveau white label pour cet utilisateur: "
      ]
    },
    'keyword' : {
        'bracketsError' : [
          "Brackets { } can contain the whole keyword and can't include special characters.",
          "Les crochets { } doivent contenir le mot clé complet et ne pas inclure de caractères spéciaux."
        ],
        'invalidTag' : [
          "Invalid tag.",
          "Le tag est invalide."
        ],
        'wrongBracketsOrder' : [
          "Square brackets [ ] order is incorrect, you cannot start with a closing ]",
          "L'ordre des crochets [ ] est incorrect, vous ne pouvez pas commencer par ] fermant."
        ],
        'missingOpeningBrackets' : [
          "Missing opening bracket [",
          "Crochet d'ouverture [ manquant."
        ],
        'missingClosingBrackets' : [
          "Missing closing bracket ]",
          "Crochet de fermeture ] manquant."
        ],
        'nonValidBrackets' :[
          "Invalid expression between square brackets [ ], it should not be empty ,should not nest and should not contain a phrase match.",
          "Expression non valide entre les crochets [ ], ne doit pas être vide, ni récursif, ni contenir une expression exacte."
        ],
        'bracketsSpaceError' :[
          "Closing square bracket ] cannot be directly followed by an opening square bracket [",
          "Crochet fermant ] ne peut pas être suivi directement par un crochet ouvrant ["
        ],
        'onlyBracketsInLine' : [
          "You cannot have only optional words in one keyword. If you want an exact match use {your keyword} instead.",
          "Vous ne pouvez pas avoir que des mots facultatifs dans un mot-clé. Si vous voulez une correspondance exacte, utilisez {votre mot-clé} à la place."
        ],
        'plusError': [
          "+ needs to be followed by either a valid tag or word and can only be preceeded by a whitespace or an opening bracket.",
          "+ doit être suivi d'une balise ou d'un texte valide et ne peut être précédé que par un espace ou un crochet d'ouverture."
        ],
        'quoteError' : [
          "Phrase match cannot be directly preceded or followed by a word, and should not contain special characters",
          "L'expression exacte ne peut pas être directement précédée ou suivie d'un mot et ne doit pas contenir de caractères spéciaux"
        ],
        'specialCharError' : [
          "You cannot use special characters in keywords.",
          "Vous ne pouvez pas utiliser de caractères spéciaux dans les mots-clés."
        ],
        'tagError' : [
          "Tags should not be included in negative keywords.",
          "Les tags ne doivent pas être inclus dans les mots clés négatifs."
        ],
        'minusSignError' : [
          "Keywords cannot start with a minus sign.",
          "Les mots clés ne peuvent pas commencer par un signe moins."
        ],
        'wordsError' : [
            "A keyword cannot exceed 5 words.",
            "Un mot-clé ne peut pas dépasser 5 mots."
        ],
        'empty' : [
            'You must have at least one keyword.',
            'Vous devez avoir au moins un mot-clé.'
        ],
        'spaceError' : [
            "You cannot have 2 consecutive spaces.",
            "Vous ne pouvez pas avoir 2 espaces consécutifs."
        ],
        'lengthError' : [
            "A keyword cannot exceed 80 characters.",
            "Un mot-clé ne peut pas dépasser 80 caractères."
        ],
        'posKeywords': [
            "Positive keywords",
            "Mots-clés positifs"
        ],
        'negKeywords' : [
            "Negative keywords",
            "Mots-clés négatifs"
        ],
        'keywordsError' : [
            "The keywords you entered are invalid",
            "Les mots-clés que vous avez rentré sont invalides"
        ]
    },
    'common' : {
        'import' : [
          "Import",
          "Importer"
        ],
        'next' : [
          "Next",
          "Suivant"
        ],
        'previous' : [
          "Previous",
          "Précédent"
        ],
        'exit' : [
          "Exit",
          "Quitter"
        ],
        'add' : [
          'Add',
          'Ajouter'
        ],
        'preview' : [
          "Preview",
          "Aperçu"
        ],
        'NOTHING' : [
            "",
            ""
        ],
        'yes' : [
            "Yes",
            "Oui"
        ],
        'no' : [
            "No",
            "Non"
        ],
        'confirm' : [
            "Confirm",
            "Confirmer"
        ],
        'save' : [
            "Save",
            "Sauvegarder"
        ],
        'saveAndClose' : [
            "Save & Close",
            "Sauvegarder & Fermer"
        ],
        'cancel' : [
            "Cancel",
            "Annuler"
        ],
        'reset' : [
            "Reset",
            "Réinitialiser"
        ],
        'remove' : [
            "Remove",
            "Enlever"
        ],
        'users' : [
            "Users",
            "Utilisateurs"
        ],
        'spent' : [
            "Spent",
            "Dépensé"
        ],
        'english' : [
            "English",
            "Anglais"
        ],
        'french' : [
            "French",
            "Français"
        ],
        'ENCapsFeminine' : [
            "ENGLISH",
            "ANGLAISE"
        ],
        'FRCapsFeminine' : [
            "FRENCH",
            "FRANÇAISE"
        ],
        'impressions' : [
            "Impressions",
            "Impressions"
        ],
        'clicks' : [
            "Clicks",
            "Clics"
        ],
        'cost' : [
            "Cost",
            "Coût"
        ],
        'conversions' : [
            "Conversions",
            "Conversions"
        ],
        'averagePosition' : [
            "Average Position",
            "Position Moyenne"
        ],
        'averageposition' : [
            "Average Position",
            "Position Moyenne"
        ],
        'impressionShare' : [
            "Impression Share",
            "Part des Impressions"
        ],
        'impressionshare' : [
            "Impression Share",
            "Part des Impressions"
        ],
        'CPC' : [
            "CPC",
            "CPC"
        ],
        'cpc' : [
            "CPC",
            "CPC"
        ],
        'CTR' : [
            "CTR",
            "CTR"
        ],
        'ctr' : [
            "CTR",
            "CTR"
        ],
        'new' : [
            "New",
            "Neuf"
        ],
        'used' : [
            "Used",
            "Usagé"
        ],
        'conquest' : [
            "Conquest",
            "Conquête"
        ],
        'branding' : [
            "Branding",
            "Marque"
        ],
        'service' : [
            "Service",
            "Service"
        ],
        'credit' : [
            "Credit",
            "Crédit"
        ],
        'active' : [
            "Active",
            "Active"
        ],
        'paused' : [
            "Paused",
            "Arrêté"
        ],
        'goBack' : [
            "Go Back",
            "Retour"
        ],
        'car' : [
            "Car",
            "Voiture"
        ],
        'noData' : [
            "No data in selected period",
            "Aucune donnée dans la période sélectionnée"
        ],
        'close' : [
            "Close",
            "Fermer"
        ],
        'edit' : [
            "Edit",
            "Modifier"
        ],
        'undo' : [
            "Undo",
            "Annuler"
        ],
        'delete' : [
            "Delete",
            "Supprimer"
        ],
        'copy' : [
            "Copy",
            "Copier"
        ],
        'step' : [
            "Step",
            "Étape"
        ],
        'stepUPPERCASE' : [
            "STEP",
            "ÉTAPE"
        ],
        'title' : [
            "Title",
            "Titre"
        ],
        'language' : [
            "Language",
            "Langue"
        ],
        'state' : [
            "State",
            "État"
        ],
        "year" : [
            "Year",
            "Année"
        ],
        "make" : [
            "Make",
            "Fabriquant"
        ],
        "model" : [
            "Model",
            "Modèle"
        ],
        'or' : [
            "or",
            "ou"
        ],
        'day' : [
            "Day",
            "Jour"
        ],
        'days' : [
            "Days",
            "Jours"
        ],
        'hour' : [
            "Hour",
            "Heure"
        ],
        'hours' : [
            "Hours",
            "Heures"
        ],
        'min' : [
            "Min",
            "Min"
        ],
        'sec' : [
            "Sec",
            "Sec"
        ],
        'months' : {
            '01' : [
                "January",
                "Janvier"
            ],
            '02' : [
                "February",
                "Février"
            ],
            '03' : [
                "March",
                "Mars"
            ],
            '04' : [
                "April",
                "Avril"
            ],
            '05' : [
                "May",
                "Mai"
            ],
            '06' : [
                "June",
                "Juin"
            ],
            '07' : [
                "July",
                "Juillet"
            ],
            '08' : [
                "August",
                "Août"
            ],
            '09' : [
                "September",
                "Septembre"
            ],
            '10' : [
                "October",
                "Octobre"
            ],
            '11' : [
                "November",
                "Novembre"
            ],
            '12' : [
                "December",
                "Décembre"
            ],
        },
        'shortMonths' : {
            "0" : [
                "Jan",
                "Jan"
            ],
            "1" : [
                "Feb",
                "Fev"
            ],
            "2" : [
                "Mar",
                "Mar"
            ],
            "3" : [
                "Apr",
                "Avr"
            ],
            "4" : [
                "May",
                "Mai"
            ],
            "5" : [
                "Jun",
                "Jun"
            ],
            "6" : [
                "Jul",
                "Jul"
            ],
            "7" : [
                "Aug",
                "Aou"
            ],
            "8" : [
                "Sep",
                "Sep"
            ],
            "9" : [
                "Oct",
                "Oct"
            ],
            "10" : [
                "Nov",
                "Nov"
            ],
            "11" : [
                "Dec",
                "Dec"
            ],
        },
        'weekdays' : {
            'sunday' : [
                "Sunday",
                "Dimanche"
            ],
            'monday' : [
                "Monday",
                "Lundi"
            ],
            'tuesday' : [
                "Tuesday",
                "Mardi"
            ],
            'wednesday' : [
                "Wednesday",
                "Mercredi"
            ],
            'thursday' : [
                "Thursday",
                "Jeudi"
            ],
            'friday' : [
                "Friday",
                "Vendredi"
            ],
            'saturday' : [
                "Saturday",
                "Samedi"
            ],
            'su' : [
                "Su",
                "Di"
            ],
            'mo' : [
                "Mo",
                "Lu"
            ],
            'tu' : [
                "Tu",
                "Ma"
            ],
            'we' : [
                "We",
                "Me"
            ],
            'th' : [
                "Th",
                "Je"
            ],
            'fr' : [
                "Fr",
                "Ve"
            ],
            'sa' : [
                "Sa",
                "Sa"
            ],
            'sun' : [
                "Sun",
                "Dim"
            ],
            'mon' : [
                "Mon",
                "Lun"
            ],
            'tue' : [
                "Tue",
                "Mar"
            ],
            'wed' : [
                "Wed",
                "Mer"
            ],
            'thu' : [
                "Thu",
                "Jeu"
            ],
            'fri' : [
                "Fri",
                "Ven"
            ],
            'sat' : [
                "Sat",
                "Sam"
            ],
        },
        'comingSoon' : [
            "Coming soon!",
            "En ligne bientôt!"
        ],
        'readOnlyMessage' : [
            "You are in read only mode",
            "Vous êtes en mode lecture seule"
        ],
        'accessDenied' : [
          "You do not have access to this section",
          "Vous n'avez pas accès à cette section"
        ],
        'ads' : [
          "Ads",
          "Annonces"
        ],
        'ok' : [
          "OK",
          "OK"
        ],
        'warning' : [
          "Warning",
          "Attention"
        ]
    },
    'hints' : {
      'oneKeywordPerLine' : [
        "One keyword per line",
        "Un mot-clé par ligne"
      ],
    },
    'menu' : {
        'noAccount' : [
            "No account",
            "Aucun compte"
        ],
        'demoAccountName' : [
            "Demo Account",
            "Compte demo"
        ],
        'accountsModal' : {
            'loading' : [
                "Loading accounts",
                "Chargement des comptes"
            ],
            'button' : [
                "Accounts",
                "Comptes"
            ],
            'header' : [
                "Select an account",
                "Sélectionner un compte"
            ],
            'noAccount' : [
                "No account found",
                "Aucun compte trouvé"
            ]
        },
        'buttons' : {
            [BUTTONS.DASHBOARD] : [
                "Dashboard",
                "Tableau de Bord"
            ],
            [BUTTONS.CAMPAIGNS] : [
                "Campaigns",
                "Campagnes"
            ],
            [BUTTONS.SEARCH] : [
                "Search",
                "Recherches"
            ],
            [BUTTONS.DISPLAY] : [
                "Display",
                "Bannières"
            ],
            [BUTTONS.DISPLAY_RMKT] : [
                "Display-Rmkt",
                "Bannières-Rmkt"
            ],
            [BUTTONS.REMARKETING] : [
                "Remarketing",
                "Remarketing"
            ],
            [BUTTONS.LEADS] : [
                "Leads",
                "Prospects"
            ],
            [BUTTONS.REPORTS] : [
                "Reports",
                "Rapports"
            ],
            [BUTTONS.ADS_REPORT] : [
                "Google Ads",
                "Google Ads"
            ],
            [BUTTONS.BING_REPORT] : [
                "Microsoft Ads",
                "Microsoft Ads"
            ],
            [BUTTONS.WAZE] : [
                "Waze",
                "Waze"
            ],
            [BUTTONS.PARAMS] : [
                "Params",
                "Params"
            ],
            [BUTTONS.BUDGETS] : [
                "Budgets",
                "Budgets"
            ],
            [BUTTONS.GEOTARGET] : [
                "Geo target",
                "Geo ciblage"
            ],
            [BUTTONS.ADCOPY] : [
                "AdCopies",
                "AdCopies"
            ],
            [BUTTONS.KEYWORDS] : [
                "Keywords",
                "Mots-clés"
            ],
            [BUTTONS.RULES] : [
                "Rules",
                "Règles"
            ],
            [BUTTONS.STRATEGIES] : [
                "Strategies",
                "Stratégies"
            ],
            [BUTTONS.ACCOUNT] : [
                "Account",
                "Compte"
            ],
            [BUTTONS.PROFILE] : [
                "Profile",
                "Profil"
            ],
            [BUTTONS.SYSTEM] : [
                "System",
                "Système"
            ],
            [BUTTONS.MANAGEMENT] : [
                "Management",
                "Gestion"
            ],
            [BUTTONS.CREATE_USER] : [
                "Create User",
                "Créer utilisateur"
            ],
            [BUTTONS.USERS] : [
                "Users",
                "Utilisateurs"
            ],
            [BUTTONS.ACCOUNTS] : [
              "Accounts",
              "Comptes"
            ],
            [BUTTONS.AD_BUILDER] : [
                "Ad Builder",
                "Ad Builder"
            ],
            [BUTTONS.PACY] : [
                "Budgets",
                "Budgets"
            ],
            [BUTTONS.BRANDING] : [
                "Branding",
                "Marque"
            ],
            [BUTTONS.FEEDS] : [
                "Feeds",
                "Flux"
            ],
        },
        'periods' : {
            'today' : [
                "Today",
                "Aujourd'hui"
            ],
            'yesterday' : [
                "Yesterday",
                "Hier"
            ],
            'lastWeek' : [
                "Last week",
                "La semaine dernière"
            ],
            'currentWeek' : [
                "Current week",
                "Cette semaine"
            ],
            'lastMonth' : [
                "Last month",
                "Le mois dernier"
            ],
            'currentMonth' : [
                "Current month",
                "Ce mois-ci"
            ],
            'last30Day' : [
                "Last 30 days",
                "30 derniers jours"
            ],
            'last7Day' : [
                "Last 7 days",
                "7 derniers jours"
            ],
            'last14Day' : [
                "Last 14 days",
                "14 derniers jours"
            ],
        }
    },
    'dashboard' : {
        'title' : [
            "Dashboard",
            "Tableau de bord"
        ],
        'subtitle' : [
            "Overview of your account",
            "Aperçu de votre compte"
        ],
        'graph' : {
            'noData' : [
              "No performance data in the selected period",
              "Aucune donnée de performance dans la période sélectionnée"
            ],
            'title' : [
                "Account performance trend",
                "Tendance de la performance du compte"
            ],
            'impressions' : [
                "Impressions",
                "Impressions"
            ],
            'clicks' : [
                "Clicks",
                "Clics"
            ],
            'cost' : [
                "Cost",
                "Coût"
            ],
            'conversions' : [
                "Conversions",
                "Conversions"
            ],
            'averagePosition' : [
                "Average Position",
                "Position Moyenne"
            ],
            'searchImpressionShare' : [
                "Search Impression Share",
                "Part des Impressions"
            ],
            'searchClickShare' : [
                "Search Click Share",
                "Part des Clics"
            ],
            'CPC' : [
                "CPC",
                "CPC"
            ],
            'conversionRate' : [
              "Conversion Rate",
              "Taux de Conversion"
            ],
            'CPCon' : [
              "Cost Per Conversion",
              "Coût par Conversion"
            ],
            'compare' : [
                "Compare",
                "Comparer"
            ]
        },
        'mapSpend' : {
            'showTargeting' : [
                "Show targeting",
                "Afficher ciblage"
            ],
            'adSpend' : [
                "Ad spend",
                "Dépenses publicitaire"
            ],
            'spent' : [
                "Spent ${value} over ${day} days",
                "${value} dépensé en ${day} jours"
            ],
            'share' : [
                "${value} impression share",
                "${value} des impressions du marché"
            ],
            'shareLost' : [
                "${value} impression share lost because of budget",
                "${value} des impressions perdues à cause du budget"
            ],
            'recommended' : [
                "${value} recommended additional budget per month",
                "${value} additionnel au budget par mois recommandé"
            ],
            'strategies' : [
                "Strategies",
                "Stratégies"
            ],
        },
        'missedOpportunities' : {
            'missedOpp' : [
                "Missed opportunities",
                "Opportunités manquées"
            ],
            'share' : [
                "Share",
                "Part"
            ],
            'noMissed1' : [
                "Good news!",
                "Bonne nouvelle!"
            ],
            'noMissed2' : [
                "No missed opportunities",
                "Aucune opportunité manquées"
            ],
            'highlightTooltip' : [
                "Missed opportunity on a trending car",
                "Opportunité manquée sur un véhicule populaire"
            ],
        },
        'trendy' : {
            'trendy' : [
                "Trendy cars",
                "Véhicules populaires"
            ],
            'interest' : [
                "Interest",
                "Intérêt"
            ],
            'highlightTooltip' : [
                "Missed opportunity on a trending car",
                "Opportunité manquée sur un véhicule populaire"
            ],
            'noTrendyCars' : [
                "No trendy cars in inventory",
                "Aucun véhicules populaires en inventaire"
            ],
        },
        'rules' : {
            'engRules' : [
                "Engagement rules",
                "Règles d'engagement"
            ],
            'stateCars' : [
                "${state} Cars",
                "Véhicules ${state}"
            ],
            'edit' : [
                "Edit",
                "Modifier"
            ],
        },

        'KPIs' : {
            'impressions' : [
                "Impressions",
                "Impressions"
            ],
            'clicks' : [
                "Clicks",
                "Clics"
            ],
            'averagePosition' : [
                "Average Position",
                "Position Moyenne"
            ],
            'averageposition' : [
                "Average Position",
                "Position Moyenne"
            ],
            'CPC' : [
                "Cost per Click",
                "Coût par Click"
            ],
            'cpc' : [
                "Cost per Click",
                "Coût par Click"
            ],
            'cost' : [
                "Cost",
                "Coût"
            ],
            'impressionShare' : [
                "Impression Share",
                "Part des Impressions"
            ],
            'impressionshare' : [
                "Impression Share",
                "Part des Impressions"
            ],
            'clickShare' : [
                "Click Share",
                "Part des Clics"
            ],
            'clickshare' : [
                "Click Share",
                "Part des Clics"
            ],
            'conversions' : [
                "Conversions",
                "Conversions"
            ],
            'CTR' : [
                "Click Through Rate",
                "Taux de Clics"
            ],
            'ctr' : [
                "Click Through Rate",
                "Taux de Clics"
            ],
            'conversionrate' : [
              "Conversion Rate",
              "Taux de Conversion"
            ],
            'cpcon' : [
              "Cost Per Conversion",
              "Coût par Conversion"
            ]
        },
        'budgetsInsight' : {
            'tooltips' : {
                [DetailsOptions.impressions] : [
                  'Impressions are how many times advertisements were displayed to users, regardless of whether the ads were clicked',
                  "Les impressions sont le nombre de fois où les publicités ont été affichées pour les utilisateurs, que les publicités aient été cliquées ou non"
                ],
                [DetailsOptions.clicks] : [
                  "How many times this account's advertisements were clicked",
                  "Combien de fois les publicités de ce compte ont été cliquées"
                ],
                [DetailsOptions.cpc] : [
                  'The cost paid when an individual advertisement is clicked',
                  "Le coût payé lorsqu'un utilisateur clique sur une annonce individuelle"
                ],
                [DetailsOptions.cost] : [
                  'The total amount spent on advertising',
                  "Le montant total dépensé en publicité"
                ],
                [DetailsOptions.impShare] : [
                  'The portion of impressions that this account received compared to all impressions it was eligible for',
                  "La part d'impressions que ce compte a reçue par rapport à toutes les impressions auxquelles il était éligible"
                ],
                [DetailsOptions.clickShare] : [
                  'An estimated share of received clicks compared to all possible clicks',
                  "Une part estimée des clics reçus par rapport à tous les clics possibles"
                ],
                [DetailsOptions.conversions] : [
                  'Conversions are beneficial actions that a user takes after clicking an ad - for example, making a puchase or calling your business',
                  "Les conversions sont des actions bénéfiques qu'un utilisateur prend après avoir cliqué sur une annonce - par exemple, faire un achat ou appeler votre entreprise"
                ],
                [DetailsOptions.ctr] : [
                  'The ratio of users who clicked on ads compared to the total number of users for whom they were displayed',
                  "Le nombre d'utilisateurs qui ont cliqué sur des annonces par rapport au nombre total d'utilisateurs pour lesquels elles ont été affichées"
                ],
                [DetailsOptions.conversionrate] : [
                  "Conversion rate is the percentage of visitors who, upon being shown an ad, click and continue through to a beneficial action such as making a purchase",
                  "Le taux de conversion est le pourcentage de visiteurs qui, après avoir vu une annonce, cliquent et poursuivent une action bénéfique telle qu'un achat"
                ],
                [DetailsOptions.cpcon] : [
                  "The total cost, on average, of driving a consumer to a beneficial action such as making a purchase or calling your business",
                  "Le coût total, en moyenne, de conduire un consommateur à une action bénéfique comme faire un achat ou appeler votre entreprise"
                ],
            },
            'title' : [
                "${dataName} by budgets",
                "${dataName} par budgets"
            ],
            'noData' : [
                "No ${dataName} on any budget in selected period",
                "Aucune données dans les budgets durant la période sélectionnée"
            ],
            'KPITooltipOpen' : [
                "Click for more details",
                "Cliquez pour plus de détails"
            ],
            'KPITooltipClose' : [
                "Click to close details",
                "Cliquez pour fermer les détails"
            ],
            'spend' : [
                "Spend",
                "Dépense"
            ],
            'impressions' : [
                "Impressions",
                "Impressions"
            ],
            'clicks' : [
                "Clicks",
                "Clics"
            ],
            'avgPosition' : [
                "Average Position",
                "Position Moyenne"
            ],
            'cpc' : [
                "Cost per Click",
                "Coût par Clic"
            ],
            'cost' : [
                "Cost",
                "Coût"
            ],
            'impShare' : [
                "Impression Share",
                "Part des Impressions"
            ],
            'clickShare' : [
                "Click Share",
                "Part des Clics"
            ],
            'conversions' : [
                "Conversions",
                "Conversions"
            ],
            'ctr' : [
                "Click Through Rate",
                "Taux de Clics"
            ],
            'conversionrate' : [
                "Conversion Rate",
                "Taux de Conversion"
            ],
            'cpcon' : [
                "Cost Per Conversion",
                "Coût Par Conversion"
            ],
            'budgets' : {
                'others' : [
                    "Others",
                    "Autres"
                ],
                'unknown' : [
                    "Unknown budget",
                    "Budget inconnu"
                ],
                'branding' : [
                    "Branding",
                    "Marque"
                ],
                'credit' : [
                    "Credit",
                    "Crédit"
                ],
                'conquest' : [
                    "Conquest",
                    "Conquête"
                ],
                'conquestnew' : [
                    "Conquest new",
                    "Conquête neuf"
                ],
                'rmktcredit' : [
                    "Remarketing credit",
                    "Remarketing crédit"
                ],
                'display' : [
                    "Display",
                    "Bannières"
                ],
                'service' : [
                    "Service",
                    "Service"
                ],
                'rmktused' : [
                    "Remarketing used",
                    "Remarketing usagé"
                ],
                'conquestused' : [
                    "Conquest used",
                    "Conquête usagé"
                ],
                'used' : [
                    "Used",
                    "Usagé"
                ],
                'new' : [
                    "New",
                    "Neuf"
                ],
                'rmktnew' : [
                    "Remarketing new",
                    "Remarketing neuf"
                ],
                'rmktbranding' : [
                    "Remarketing branding",
                    "Remarketing marque"
                ],
                'rmktservice' : [
                    "Remarketing service",
                    "Remarketing service"
                ],
                'remarketing credit' : [
                    "Remarketing credit",
                    "Remarketing crédit"
                ],
                'remarketing used' : [
                    "Remarketing used",
                    "Remarketing usagé"
                ],
                'remarketing new' : [
                    "Remarketing new",
                    "Remarketing neuf"
                ],
                'remarketing branding' : [
                    "Remarketing branding",
                    "Remarketing marque"
                ],
                'remarketing service' : [
                    "Remarketing service",
                    "Remarketing service"
                ],
            }
        }
    },
    'inventoryTurn' : {
        'title' : [
            "Analysis",
            "Analyse"
        ],
        'subtitle' : [
            "Inventory turn",
            "Roulement d'inventaire"
        ],
        'moreButton' : [
            "More",
            "Plus"
        ],
        'KPIs' : {
            'visited' : [
                "Vehicles visited",
                "Véhicules visités"
            ],
            'DBTraffic' : [
                "${company}'s traffic",
                "Trafic de ${company}"
            ],
            'estimated' : [
                "Estimated",
                "Estimé"
            ],
            'sold' : [
                "Sold Vehicles",
                "Véhicules vendus"
            ],
            'proportion' : [
                "Proportion of sales",
                "Proportion des ventes"
            ],
            'influenced' : [
                "influenced by ${company}",
                "influencées par ${company}"
            ],
        },
        'table' : {
            'YMM' : [
                "Year Make Model",
                "Année Fabriquant Modèle"
            ],
            'visitors' : [
                "Visitors\r\nfrom ${company}\r\n",
                "Visiteurs provenant\r\nde ${company}\r\n"
            ],
            'days' : [
                "Days in\r\ninventory\r\n",
                "Jours en\r\ninventaire\r\n"
            ],
            'uniqueVisitors' : [
                "Unique\r\nvisitors\r\n",
                "Visiteurs\r\nuniques\r\n"
            ],
            'visitsRank' : [
                "Visits\r\nrank\r\n",
                "Classements\r\ndes visites\r\n"
            ],
            'noRemovedMessage' : [
              "No vehicles were removed from your website in the selected period",
              "Aucun véhicule n'a été retiré de votre site dans la période sélectionnée"
            ],
            'noInfluencedMessage' : [
              "Unable to track any vehicles that we influenced the sale of in the selected period",
              "Impossible de déterminer si nous avons influencé la vente de véhicules dans la période sélectionnée"
            ],
        },
        'graphs' : {
            'staffSection' : [
                "This section is still in beta testing",
                "Cette section est encore en phase beta"
            ],
            'all' : [
                "All",
                "Tout"
            ],
            'daysInInventory' : [
                "Days in inventory",
                "Jours en inventaire"
            ],
            'visitorsDB' : [
                "Visitors from DB",
                "Visiteurs de DB"
            ],
            'usedInventory' : [
                "Used inventory",
                "Inventaire usagé"
            ],
            'newInventory' : [
                "New inventory",
                "Inventaire neuf"
            ],
            'numberVehicles' : [
                "Number of vehicles",
                "Nombre de véhicules"
            ],
            'avgDays' : [
                "Average days in inventory",
                "Jours en inventaire moyen"
            ],
        },
    },
    'campaigns' : {
        'search' : [
            "Search",
            "Recherches"
        ],
        'display' : [
            "Display",
            "Bannières"
        ],
        'displayRmkt' : [
            "Display-Rmkt",
            "Bannières-Rmkt"
        ],
        'remarketing' : [
            "Remarketing",
            "Remarketing"
        ],
        'searchSubtitle' : [
            "Explore your search campaigns",
            "Explorez vos campagnes de recherche"
        ],
        'displaySubtitle' : [
            "Explore your display campaigns",
            "Explorez vos campagnes de bannières"
        ],
        'displayRmktSubtitle' : [
            "Explore your display and remarketing campaigns",
            "Explorez vos campagnes de bannières et de remarketing"
        ],
        'remarketingSubtitle' : [
            "Explore your remarketing campaigns",
            "Explorez vos campagnes de remarketing"
        ],
        'campaignNotFound' : [
            "This campaign does not exist",
            "Cette campagne n'existe pas"
        ],
        'goBackToList' : [
            "Go back to campaigns list",
            "Retour à la liste de campagnes"
        ],
        'list' : {
            'title' : [
                "Campaigns",
                "Campagnes"
            ],
            'subtitle' : [
                "Explore your automated search campaigns",
                "Explorez vos campagnes de recherche automatisées"
            ],
            'noCampaigns' : [
                "No campaigns for selected account",
                "Pas de campagnes pour le compte sélectionné"
            ],
            'status' : [
                "Status",
                "Statut"
            ],
            'stock' : [
                "Stock",
                "Stock"
            ],
            'campaign' : [
                "Campaign",
                "Campagne"
            ],
            'inventory' : [
                "Inventory",
                "Inventaire"
            ],
            'group' : [
                "Group",
                "Groupe"
            ],
            'daysInInventory' : [
                "Days In Inventory",
                "Jours En Inventaire"
            ],
            'noInventory' : [
                "No inventory",
                "Pas d'inventaire"
            ],
            'notApplicable' : [
                "N/A",  // Text used by logic in page/campaign/component/shared/CampaignsTable.tsx
                "N/A"
            ],
            'avg' : [
              "Avg. ",
              "Moy. "
            ]
        },
        'exceptionModal' : {
            'header' : [
                "Campaign Status Exception",
                "Exception du statut de la campagne"
            ],
            'active' : [
                "Active",
                "Active"
            ],
            'paused' : [
                "Paused",
                "Arrêtée"
            ],
            'noException' : [
                "No exception",
                "Aucune exception"
            ],
            'currentException' : [
                "Current exception :",
                "Exception actuelle :"
            ],
            'currentlyEnabled' : [
                "Current exception : Enabled",
                "Exception actuelle : Activée"
            ],
            'currentlyPaused' : [
                "Current exception : Paused",
                "Exception actuelle : Arrêtée"
            ],
            'forceEnabled' : [
                "Force Enabled",
                "Forcer l'activation"
            ],
            'forcePaused' : [
                "Force Paused",
                "Forcer l'arrêt"
            ],
            'removeException' : [
                "Remove Exception",
                "Enlever l'exception"
            ],
        },
        'sections' : {
            'overview' : [
                "Overview",
                "Vue d'ensemble"
            ],
            'geo' : [
                "Geo",
                "Geo"
            ],
            'adCopy' : [
                "AdCopy",
                "AdCopy"
            ],
            'hint' : [
                "Details",
                "Détails"
            ],
        },
        'overview' : {
            'totalClicks' : [
                "Total of clicks",
                "Total des clics"
            ],
            'avgCPC' : [
                "Avg CPC",
                "CPC moyen"
            ],
            'totalCost' : [
                "Total of cost",
                "Total des coûts"
            ],
            'byDevice' : [
                "by device",
                "par appareil"
            ],
            'deviceData' : {
                [DeviceStatistics.impressions] : [
                    'Impressions',
                    'Impressions'
                ],
                [DeviceStatistics.clicks] : [
                    'Clicks',
                    'Clics'
                ],
                [DeviceStatistics.conversions] : [
                    'Conversions',
                    'Conversions'
                ],
                [DeviceStatistics.cost] : [
                    'Cost',
                    'Coût'
                ]
            },
            'platforms' : {
              'desktop' : [
                  "Desktop",
                  "Bureau"
              ],
              'high_end_mobile' : [
                  "Smartphone",
                  "Téléphone Intelligent"
              ],
              'connected_tv' : [
                  "Smart TV",
                  "TV Intelligente"
              ],
              'tablet' : [
                  "Tablet",
                  "Tablette"
              ],
              'unknown' : [
                  "Unknown",
                  "Inconnu"
              ],
            },
            'placement' : [
                "Placement",
                "Placement"
            ],
            'searchTerms' : [
                "Search terms",
                "Termes de recherche"
            ],
            'keywords' : [
                "Keywords",
                "Mots-clés"
            ],
            'avgPos' : [
                "Avg. pos.",
                "Pos. moy."
            ],
            'searchTermsTitle' : [
                "Searches that triggered the campaign",
                "Recherches qui ont déclenché la campagne"
            ],
            'placementsTitle' : [
                "Places where the campaign appeared",
                "Endroits où la campagne a apparue"
            ]

        },
        'geo' : {
            'saveButton' : [
                "Save exception",
                "Sauvegarder exception"
            ],
            'removeButton' : [
                "Remove exception",
                "Enlever exception"
            ],
            'exceptionMessage' : [
                "This campaign has an exception on it's geo targeting",
                "Cette campagne a une exception sur son ciblage géographique"
            ],
            'confirmationMessage' : [
                "Do you really want to remove the exception?",
                "Voulez-vous vraiment enlever l'exception?"
            ],

        },
        'adCopy' : {
            'nameFR' : [
                "Automated FR",
                "FR Automatisé"
            ],
            'nameEN' : [
                "Automated EN",
                "EN Automatisé"
            ],
            'nameRLSAFR' : [
                "Automated RLSA FR",
                "RLSA FR Automatisé "
            ],
            'nameRLSAEN' : [
                "Automated RLSA EN",
                "RLSA EN Automatisé"
            ],
        },
        'hint' : {
            'price' : [
                "Price",
                "Prix"
            ],
            'pictures' : [
                "pictures",
                "photos"
            ],
            'startingPrice' : [
                "Starting price",
                "Prix de départ"
            ],
            'inInventory' : [
                "in inventory",
                "en inventaire"
            ],
            'campaign' : [
                "Campaign",
                "Campagne"
            ],
            'finalURL' : [
                "Final URL",
                "URL de destination"
            ],
            'status' : [
                "Status",
                "Statut"
            ],
            'language' : [
                "Language",
                "Langue"
            ],
            'AWID' : [
                "Google Ads ID",
                "ID Google Ads"
            ],
            'BingID' : [
                "Microsoft Ads ID",
                "ID Microsoft Ads"
            ],
            'name' : [
                "Name",
                "Nom"
            ],

        },
    },
    'feeds' : {
      'empty-cta' : [
        "Feeds allow you to make data accessible to third party providers like Google and Facebook",
        "Les flux vous permettent de rendre des données accessibles à des fournisseurs tiers comme Google ou Facebook",
      ],
      'feed' : [
        "Feed",
        "Flux de données",
      ],
      'feeds' : [
        "Feeds",
        "Flux de données",
      ],
      'create-feed':{
        'cta' : [
          "Activate Feeds",
          "Activer un flux de données",
        ],
        'title' : [
          "Create Feed",
          "Créer un flux",
        ],
        'confirm' : [
          "Generate",
          "Générer",
        ],
        'cancel' : [
          "Cancel",
          "Annuler",
        ],
        'feedType' : [
          "Feed Type",
          "Type du Flux"
        ],
        'AW_V1' : [
          "Google Ads (GDN) V1",
          "Google Ads (GDN) V1",
        ],
        'MI_AW_V1' : [
          "Google Ads (GDN) V1 - Motoinsight",
          "Google Ads (GDN) V1 - Motoinsight",
        ],
        'FB_V1' : [
          "Facebook Ads V1",
          "Facebook Ads V1",
        ],
        'MI_FB_V1' : [
          "Facebook Ads V1 - Motoinsight",
          "Facebook Ads V1 - Motoinsight",
        ],
        'GEOFENCING_PV_V1' : [
          "GeoFencing Present Value V1",
          "GeoFencing Present Value V1"
        ],
        'GEOFENCING_NPV_V1' : [
          "GeoFencing Net Present Value V1",
          "GeoFencing Net Present Value V1"
        ],
        'DCM_V1' : [
          "Google Campaign Manager (DCM) V1",
          "Google Campaign Manager (DCM) V1"
        ],
        'MI_DCM_V1' : [
          "Google Campaign Manager (DCM) V1 - Motoinsight",
          "Google Campaign Manager (DCM) V1 - Motoinsight"
        ],
        'AW_SHOPPING_V1' : [
          "Google Shopping Ads (VA) V1",
          "Google Shopping Ads (VA) V1"
        ],
        'language' : [
          "Language",
          "Langue",
        ],
        'inv-language' : [
          "Website version",
          "Version du site web",
        ],
        'vehicleType' : [
          "Vehicles Type",
          "Type de véhicules",
        ],
        'vehicleSte' : [
          "Vehicles Category",
          "Catégorie de véhicules",
        ],
        'storeCodeRequired' : [
          "The Store Code is required",
          "Le Code de commerce est requis",
        ],
        'storeCodeError' : [
          "The Store Code is invalid",
          "Le Code de commerce est invalide",
        ],
      },
      'copy' : [
        "Copy",
        "Copier",
      ],
      'download' : [
        "Download",
        "Télécharger",
      ],
      'revoke' : [
        "Revoke",
        "Révoquer",
      ],
      'revokeLocked' : [
        "It is not possible to revoke this URL because it is locked",
        "Il n'est pas possible de révoquer cet URL car il est verrouillé"
      ],
      'delete' : [
        "Delete",
        "Supprimer",
      ],
      'deleteLocked' : [
        "This feed cannot be deleted because it is locked",
        "Ce flux ne peut pas être supprimé car il est verrouillé"
      ],
      'cancel' : [
        "Cancel",
        "Annuler",
      ],
      'new-url-cta' : [
        "Create One",
        "En créer un",
      ],
      'refresh' : [
        "Refresh",
        "Rafraîchir",
      ],
      'new-url-actions' : [
        "Generate New URL",
        "Générér un nouveau lien",
      ],
      'inactive' : [
        "INACTIVE",
        "INACTIF",
      ],
      'vehicleType' : {
        'CAR' : [
          "Car",
          "Voiture",
        ],
        'NPV' : [
          "NPV",
          "NPV",
        ],
      },
      'vehicleState' : {
        'USED' : [
          "Used",
          "Usagé",
        ],
        'NEW' : [
          "New",
          "Neuf",
        ],
      },
      'language-lookup' : {
          'FR' : [
            "French",
            "Français",
          ],
          'EN' : [
            "English",
            "Anglais",
          ]
        },
      'tooltip' : {
        'MOTOINSIGHT' : [
          "This feed sends users to Motoinsight",
          "Ce flux redirige les visiteurs vers Motoinsight"
        ],
        'GOOGLE' : [
          "This feed is configured to work with Google Ads (GDN)",
          "Ce flux est prévu pour s'intégrer avec Google Ads (GDN)",
        ],
        'GOOGLE_SHOPPING' : [
          "This feed is configured to work with Google Shopping Ads (VA)",
          "Ce flux est prévu pour s'intégrer avec Google Shopping Ads (VA)",
        ],
        'FACEBOOK' : [
          "This feed is configured to work with Facebook Ads",
          "Ce flux est prévu pour s'intégrer avec Facebook Ads",
        ],
        'GEOFENCING_PV' : [
          "This feed is configured to work with GeoFencing PV",
          "Ce flux est prévu pour s'intégrer avec GeoFencing PV"
        ],
        'GEOFENCING_NPV' : [
          "This feed is configured to work with GeoFencing NPV",
          "Ce flux est prévu pour s'intégrer avec GeoFencing NPV"
        ],
        'DCM' : [
          "This feed is configured to work with Google Campaign Manager (DCM)",
          "Ce flux est prévu pour s'intégrer avec Google Campaign Manager (DCM)"
        ],
        'vehicleType' : [
          "This feed uses vehicles of this type",
          "Ce flux utilise les véhicules de ce type",
        ],
        'vehicleState' : [
          "This feed uses vehicles of this category",
          "Ce flux utilise les véhicules de cette catégorie",
        ],
        'storeCode' : [
          "Store Code",
          "Code de commerce",
        ],
        'language' : [
          "Language of the feed",
          "Langue du flux de données",
        ],
        'language-inv-lang-diff' : [
          "This feed is using inventory from the ${inventoryLang} version of the website",
          "Ce flux de données utilise l'inventaire de la version ${inventoryLang} du site",
        ],
        'language-inv-lang-diff-lookup' : {
          'FR' : [
            "French",
            "française",
          ],
          'EN' : [
            "English",
            "anglaise",
          ]
        },
        'inactive' : [
          "There's no url to fetch this feed with",
          "Il n'y a aucun lien pour accéder à ce flux",
        ],
      },
      'no-urls' : [
        "You need an url for the feed to be used",
        "Un lien doit exister pour accéder à ce flux de données"
      ],
      'url-copied-confirm' : [
        "This URL was copied to your clipboard",
        "Le lien a été copié dans votre presse-papier",
      ],
      'delete-url' : {
        'cta' : [
          "Delete URL?",
          "Supprimer le lien ?",
        ],
        'warning' : [
          "This URL will cease to function right away and the feed will no longer be accessible through it",
          "Ce lien va cesser de fonctionner immédiatement et le flux de données ne sera plus accessible via ce lien",
        ],
      },
      'delete-feed' : {
        'cta' : [
          "Delete Feed?",
          "Supprimer le flux de données ?",
        ],
        'warning-1' : [
          "This will delete the feed and its configuration, as well as all of its urls.",
          "La configuration et les liens de ce flux de données seront supprimés.",
        ],
        'warning-2' : [
          "It will not longer be consumable by third parties like Google or Facebook",
          "Les tierces parties comme Google ou Facebook n'y auront plus accès",
        ],
      },
    },
    'leads' : {
        'title' : [
            "Leads",
            "Prospects"
        ],
        'webLeads' : {
            'webLeads' : [
                "Web leads",
                "Prospects web"
            ],
            'explanation1' : [
                "This table lists web leads completed by people our ads brought to your website.",
                "Cette table dresse la liste des clients potentiels dirigés vers votre site par nos annonces."
            ],
            'explanation2' : [
                "Interactions are compiled by browser, domain, url and moment.",
                "Les interactions sont rassemblées par navigateur, domaine, url et moment."
            ],
            'explanation3' : [
                "You can click on the interaction for more details about the user conversion.",
                "Vous pouvez explorer en détail les interactions d'un utilisateur en appuyant sur le bouton de sa ligne."
            ],
            'foundPeriod' : [
                "Found ${value} web leads in selected period",
                "${value} prospects web trouvé dans la période sélectionné"
            ],
            'foundUniquePhone' : [
                "${value} different phone numbers",
                "${value} numéros de téléphone uniques"
            ],
            'foundUniqueEmail' : [
                "${value} different emails",
                "${value} adresses courriel uniques"
            ],
            'dateTime' : [
                "Date Time",
                "Moment"
            ],
            'fieldName' : [
                "Field name",
                "Nom du champ"
            ],
            'valueEntered' : [
                "Value entered",
                "Valeur entrée"
            ],
            'noKnownMessage' : [
                "No known visitors with this id",
                "Aucun visiteurs connues avec cet id"
            ],
            'path' : [
                "Path",
                "Chemin"
            ],
            'referer' : [
                "Referer",
                "Source"
            ],
        },
        'calls' : {
            'calls' : [
                "Calls",
                "Appels"
            ],
            'message' : [
              "Please contact your DealerBreacher representative to request access to CallRail tracking",
              "Veuillez contacter votre représentant DealerBreacher pour demander votre accès à CallRail"
            ],
            'contact' : [
              "Contact now",
              "Contacter maintenant"
            ],
            'login' : [
              "Log into CallRail",
              "Connexion à CallRail"
            ],
        },
    },
    'reports' : {
        'navigation' : {
            'title' : [
                "Reports",
                "Rapports"
            ],
            'subtitle' : [
                "Choose a provider",
                "Choisissez un fournisseur"
            ],
            'ads' : [
                "Google Ads",
                "Google Ads"
            ],
            'adsSubtitle' : [
                "Explore the performance of your account on Google Ads",
                "Explorez la performance de votre compte sur Google Ads"
            ],
            'bing' : [
                "Microsoft Advertising",
                "Microsoft Advertising"
            ],
            'bingSubtitle' : [
                "Explore the performance of your account on Microsoft Advertising",
                "Explorez la performance de votre compte sur Microsoft Advertising"
            ],
            'waze' : [
                "Waze",
                "Waze"
            ],
            'wazeSubtitle' : [
                "Explore the performance of your account on Waze",
                "Explorez la performance de votre compte sur Waze"
            ],
            'pacy' : [
                "Budgets",
                "Budgets"
            ],
            'pacySubtitle' : [
                "View in details how your money is spent",
                "Voyez en détails comment votre argent est dépensée"
            ],
        },
        'bing': {
          'title' : [
            "Microsoft Advertising",
            "Microsoft Advertising"
          ]
        },
        'ads' : {
            'title' : [
                "Google Ads",
                "Google Ads"
            ],
            'subtitle' : [
                "Performance review",
                "Revue de performance"
            ],
            'campaign' : [
                "Campaigns",
                "Campagnes"
            ],
            'keyword' : [
                "Keywords",
                "Mots-clés"
            ],
            'view' : [
                "View",
                "Vue"
            ],
            'editView' : [
                "Edit view",
                "Modifier vue"
            ],
            'createView' : [
                "Create view",
                "Créer vue"
            ],
            'createViewHeader' : [
                "Create a new view",
                "Créer une nouvelle vue"
            ],
            'viewNameLengthError' : [
                "A view's name must be at least 3 characters long.",
                "Le nom d'une vue doit être d'une longeur minimale de 3 caractères."
            ],
            'viewNamePlaceholder' : [
                "View name",
                "Nom de la vue"
            ],
            'nameLabel' : [
                "Name:",
                "Nom:"
            ],
            'noStats' : [
              "There is no data to display for this selection.",
              "Il n'y a aucune donnée à afficher pour cette sélection."
            ],
            'editor' : {
                'checkboxDisabled' : [
                  'Only the current view exists',
                  "Seulement la vue actuelle existe"
                ],
                'defaultView' : [
                  "Default view:",
                  "Vue par défaut:"
                ],
                'delete' : [
                    "Delete",
                    "Supprimer"
                ],
                'reset' : [
                    "Reset",
                    "Réinitialiser"
                ],
                'name' : [
                    "Name",
                    "Nom"
                ],
                'default' : [
                    "Default",
                    "Défaut"
                ],
                'defaultSorted' : [
                    "Default sorted column",
                    "Colonne trié par défaut"
                ],
                'asc' : [
                    "Ascendant",
                    "Ascendant"
                ],
                'desc' : [
                    "Descendant",
                    "Descendant"
                ],
                'size' : [
                    "Size per page",
                    "Taille par page"
                ],
                'bank' : [
                    "Columns bank",
                    "Banque de colonnes"
                ],
                'yourColumns' : [
                    "Your columns",
                    "Vos colonnes"
                ],
                'noFilter' : [
                    "No filter",
                    "Aucun filtre"
                ],
                'nameError' : [
                    "A name must be at least 3 characters long",
                    "Un nom doit être d'une longueur minimale de 3 caractères."
                ],
                'sizeError' : [
                    "The size per page must be a number higher than 0",
                    "La taille par page doit être un nombre supérieur à 0"
                ],
            },
        },
        'waze' : {
            'title' : [
                "Waze",
                "Waze"
            ],
            'subtitle' : [
                "Performance Report",
                "Rapport de performance"
            ],
            'navigations' : [
                "Navigations",
                "Navigations"
            ],
            'moreInfo' : [
                "More info",
                "Plus d'information"
            ],
            'calls' : [
                "Calls",
                "Appels"
            ],
            'search' : [
                "Search",
                "Recherche"
            ],
            'pin' : [
                "Pin",
                "Épingle"
            ],
            'total' : [
                "Total",
                "Total"
            ],
            'summary' : [
                "Summary",
                "Résumé"
            ],
            'totalActions' : [
                "Total\r\nactions",
                "Total des\r\nactions"
            ],
            'actionsPerDay' : [
                "Actions\r\nper day",
                "Actions\r\npar jour"
            ],
            'totalSpent' : [
                "Total\r\nspent",
                "Total\r\ndépensé"
            ],
            'spentPerDay' : [
                "Spent\r\nper day",
                "Dépenses\r\npar jour"
            ],
        },
        'budgets' : {
            'pacingReport' : [
                "Pacing Report",
                "Rapport de Rythme"
            ],
            'decisions' : [
              "Decisions",
              "Décisions"
            ],
            'Branding' : [
                "Branding",
                "Marque"
            ],
            'Credit' : [
                "Credit",
                "Crédit"
            ],
            'New' : [
                "New",
                "Neuf"
            ],
            'Used' : [
                "Used",
                "Usagé"
            ],
            'Service' : [
                "Service",
                "Service"
            ],
        }
    },
    'params' : {
        'title' : [
            "Parameters",
            "Paramètres"
        ],
        'subtitle' : [
            "Personalize your parameters",
            "Personnalisez vos paramètres"
        ],
        'adCopies' : [
            "AdCopies",
            "AdCopies"
        ],
        'adCopiesSubtitle' : [
            "Edit your default adcopies for your campaigns",
            "Modifiez vos adcopies par défaut pour vos campagnes"
        ],
        'branding' : [
          "Branding",
          "Marque"
        ],
        'brandingSubtitle': [
          'Edit your branding campaign',
          'Modifiez votre campagne de marque'
        ],
        'strategies' : [
            "Strategies",
            "Stratégies"
        ],
        'strategiesSubtitle' : [
            "Edit your strategies",
            "Modifiez vos stratégies"
        ],
        'feeds' : [
            "Feeds",
            "Flux de données"
        ],
        'feedsSubtitle' : [
            "Edit your Remarketing Feeds",
            "Modifiez vos flux de données"
        ],
        'keywords' : [
            "Keywords",
            "Mots-clés"
        ],
        'keywordsSubtitle' : [
            "Edit your positive & negative keywords",
            "Modifiez vos mots-clés positifs & négatifs"
        ],
        'geoTarget' : [
            "Geo target",
            "Geo ciblage"
        ],
        'geoTargetSubtitle' : [
            "Edit your geo targeting",
            "Modifiez votre geo ciblage"
        ],
        'budgets' : [
            "Budgets",
            "Budgets"
        ],
        'budgetsSubtitle' : [
            "Edit your monthly budget by category",
            "Modifiez votre budget mensuel par catégorie"
        ],
        'rules' : [
            "Rules",
            "Règles"
        ],
        'rulesSubtitle' : [
            "Edit your rules by category",
            "Modifiez vos règles par catégorie"
        ],
    },
    'budgets' : {
        'title' : [
            "Budget Planner",
            "Planificateur de budget"
        ],
        'subtitle' : [
            "Planification of your budget",
            "Planifiez votre budget"
        ],
        'historyButton' : [
            "View changes history",
            "Voir l'historique des changements"
        ],
        'confirmationMessage' : [
            "Do you really want to save this information?",
            "Voulez-vous vraiment sauvegarder ces informations?"
        ],
        'account' : [
            "Account",
            "Compte"
        ],
        'provider' : [
            "Provider",
            "Fournisseur"
        ],
        'period' : [
            "Period",
            "Période"
        ],
        'budget' : [
            "Budget",
            "Budget"
        ],
        'amount' : [
            "Amount",
            "Montant"
        ],
        'inactiveMessage1' : [
            "The budget planner is not active on this account.",
            "Le planificateur de budget n'est pas actif sur ce compte."
        ],
        'inactiveMessage2' : [
            "If you think it should be, contact support.",
            "Si vous pensez qu'il le devrait, contactez le support."
        ],
        'migrateButton' : [
            "Migrate to new planner",
            "Migrer au nouveau planificateur"
        ],
        'history' : {
            'title' : [
                "Budget Planner Changes History",
                "Historique des changements du planificateur de budget"
            ],
            'subtitle' : [
                "History of the changes made through the Budget Planner",
                "Historique des changements fait avec le planificateur de budget"
            ],
            'budgetName' : [
                "Budget name",
                "Nom du budget"
            ],
            'period' : [
                "Period",
                "Période"
            ],
            'isLastRevision' : [
                "Is Last Revision",
                "est la dernière révision"
            ],
            'amount' : [
                "Amount",
                "Montant"
            ],
            'user' : [
                "User",
                "Utilisateur"
            ],
            'savedOn' : [
                "Saved On",
                "Sauvegardé le"
            ],
            'yesNo' : [
                "Yes/No",
                "Oui/Non"
            ],
        },
    },
    'adGroupCreator' : {
      'title' : [
        "Ad group creator",
        "Créateur de groupe d'annonces"
      ],
      'description' : [
        "Welcome to the ad group creator, we will guide you step by step to through the whole process, you will be able to generate ad copies and keyword from already existing ones or you can also fill in everything manually. Start by choosing a name for your new ad group and select the languages or language you want to associate your ad group with.",
        "Bienvenue dans le créateur de groupe d'annonces, nous vous guiderons pas à pas tout au long du processus, vous pourrez générer des annonces et des mots clés à partir de ceux qui existent déjà ou vous pouvez également tout saisir manuellement. Commencez par choisir un nom pour votre nouveau groupe d’annonces, puis sélectionnez les langues ou la langue à laquelle vous souhaitez associer votre groupe d’annonces."
      ],
      'chooseAdGroup' : [
        "Choose a name for your new ad group",
        "Choisissez un nom pour votre nouveau groupe d'annonces"
      ],
      'chooseLanguage' : [
        "Choose the language(s) you want to associate this ad group with",
        "Choisissez le(s) langages auxquels vous voulez associer ce groupe d'annonces "
      ],
      'noAvailableAudience' : [
        "No audience available",
        "Aucune audience disponible"
      ]
    },
    'branding' : {
        'unfilledError' : [
          "All fields must be filled before continuing",
          "Tous les champs doivent être remplis avant de continuer"
        ],
        'noStrategies' : [
          "There are no strategies to modify",
          "Il n'y a pas de stratégies à modifier"
        ],
        'readOnlyModal' : {
            'title' : [
                "Read only mode",
                "Mode lecture seule"
            ],
            'content' : [
                "You are not allowed to do modifications to the branding campaign of this account. You can explore the section to see the what is currently configured but none of your changes will be saved.",
                "Vous n'êtes pas autorisé à faire des modifications aux campagnes de marque de ce compte. Vous pouvez explorer la section pour voir ce qui a été configuré mais aucun de vos changements seront sauvegardé."
            ],
            'button1' : [
                "Proceed",
                "Procéder"
            ],
        },
        'editAds' : [
          'Edit ads',
          'Modifier les annonces'
        ],
        'editKeywords' : [
          "Edit keywords",
          "Modifier les mots-clés"
        ],
        'createAdGroup' : [
            "Create a new ad group",
            "Créer un nouveau groupe d'annonces"
        ],
        'title' : [
            "Branding",
            "Marque"
        ],
        'noCampaign' : [
            "This account doesn't have any Branding campaign",
            "Ce compte n'a aucune campagne de Marque"
        ],
        'chooseBiddingStrategy' : [
            'Choose a bidding strategy',
            'Choisir une stratégie de mise'
        ],
        'chooseAudience'  : [
            'Choose an audience',
            'Choisir une audience'
        ],
        'chooseLanguage' : [
            "Choose a language",
            "Choisir une langue"
        ],
        'chooseAdGroup' : [
            "Choose an ad group",
            "Choisir un groupe d'annonce"
        ],
        'resetMessage' : [
            "Are you sure you want to reset the current changes?",
            "Êtes-vous certain de vouloir réinitialiser les changements actuels?"
        ],
        'deleteMessage' : [
            "Are you sure you want to delete this adcopy?",
            "Êtes-vous certain de vouloir supprimer cette annonce?"
        ],
        'saveMessage' : [
            "Are you sure you want to save the current changes?",
            "Êtes-vous certain de vouloir sauvegarder les changements actuels?"
        ],
        'identityVariants' : [
            "Identity variants",
            "Variantes d'identité"
        ],
        'missingIdentityVariants' : [
            "Missing identity variants",
            "Variantes d'identité manquantes"
        ],
        'missingIdentityVariantsLang' : [
            "Missing ${lang} identity variants",
            "Variantes d'identité ${lang} manquantes"
        ],
        'dealerNames' : [
            "Dealer names",
            "Noms des concessionnaires"
        ],
        'cities' : [
            "Cities",
            "Villes"
        ],
        'makes' : [
            "Makes",
            "Marques"
        ],
        'urlValidationLength': [
            "The URL input can't be empty",
            "L'URL ne peut pas être vide"
        ],
        'urlValidationFormat' : [
            "The URL you entered is not valid",
            "L'URL que vous avez entré n'est pas valide"
        ],
        'urlFormatDescription' : [
            "The URL format should be: http(s)://example.com/...",
            "Le format de l'URL doit être: http(s)://exemple.com/..."
        ],
        'geoTargetingType' : {
            'dontCare' : [
                "People in, or who show interest in, your targeted locations (recommended)",
                "Personnes dans, ou qui manifestent un intérêt pour, vos emplacements ciblés (recommandé)"
            ],
            'areaOfInterest' : [
                "People searching for your targeted location",
                "Personnes recherchant votre emplacement ciblé"
            ],
            'locationOfPresence' : [
                "People in or regularly in your targeted locations",
                "Personnes dans ou régulièrement dans vos endroits ciblés"
            ]
        },
        'identityVariantsTester' : {
            'headline' : [
              "Headline",
              "Titre"
            ],
            'review': [
                "Review",
                "Révision"
            ],
            'previewDescription' : [
                "Review all adcopies before saving",
                "Révision de toutes les annonces avant de sauvegarder"
            ],
            'tip': [
                "Add identity variants and use dynamic tags to create more variations",
                "Ajoutez des variantes d'identité et utilisez les tags dynamiques pour créer plus de variations"
            ],
            'error' : [
                "You need at least three valid adcopies to be able to save",
                "Il faut avoir au moins 3 annonces valides pour pouvoir sauvegarder"
            ],
            'valid' : [
              "Valid",
              "Valide"
            ],
            'invalid' : [
              "Invalid",
              "Invalide"
            ],
            'isTooLong' : [
              "is too long",
              "est trop long"
            ],
            'isTooLongDes' : [
              "is too long",
              "est trop longue"
            ],
            'tipOne' : [
              "The tags <strong>&lt;month&gt;</strong> and <strong>&lt;_month&gt;</strong> always take 9 characters",
              "Les tags <strong>&lt;month&gt;</strong> et <strong>&lt;_month&gt;</strong> comptent toujours pour 9 caractères"
            ],
            'tipTwo' : [
              "You can always add and remove Identity variants from the Settings tab",
              "Vous pouvez toujours ajouter et supprimer des variantes d'identité à partir de l'onglet Paramètres"
            ]
        },
        'tabs' : {
          'settings' : [
            "SETTINGS",
            "PARAMÈTRES"
          ],
          'adGroups' : [
            "AD GROUPS",
            "GROUPES D'ANNONCES"
          ],
          'geoTargeting' : [
            "GEO-TARGETING",
            "GEO CIBLAGE"
          ],
          'audiences' : [
            "AUDIENCES",
            "AUDIENCES"
          ],
          'keywords' : [
            "KEYWORDS",
            "MOTS CLÉS"
          ]
        },
        'descriptions' : {
          'identityVariants' : [
            "Identity variants are swapped with their respective dynamic tags, the more you define the more ad copies and keywords are generated.",
            "Les variantes d'identité sont permutées avec leurs balises dynamiques respectives, plus vous en définissez, plus le nombre d'annonce et de mots-clés générés est important."
          ],
          'missingIdentityVariants' : [
            "This account is missing identity variants. Please create some now.",
            "Variantes d'identité manquantes. Veuillez en créer maintenant."
          ],
          'createIdentityVariants' : [
            "Creating variants for language(s):",
            "Création de variantes de langue(s):"
          ],
          'bidStrategy' : [
            "A bid strategy that automatically sets bids for your ads based on that ad’s likelihood to result in a click or conversion.",
            "Une stratégie d'enchères automatiques définit automatiquement les enchères de vos annonces selon la probabilité que ces dernières aboutissent à un clic ou à une conversion."
          ],
          'finalUrl' : [
            "The Final URL is the landing page of your ads.",
            "L'URL de destination est la page de destination de vos annonces."
          ],
          'positiveKeywords' : [
            "Each ad group has its own positive keywords, make sure to select the right language and ad group before making modifications.",
            "Chaque groupe d'annonces a ses propres mots clés, assurez-vous de sélectionner le bon langage et le bon groupe d'annonces avant d'apporter des modifications."
          ],
          'negativeKeywords' : [
            "Negative keywords are set for all ad groups, make sure to select the right language before making modifications.",
            "Les mots clés négatifs sont valables pour tous les groupes d'annonces, assurez-vous de sélectionner le bon langage avant d'apporter des modifications."
          ],
          'status' : [
            "Status represents the active languages for your campaing (Active if checked) you can toggle activation by clicking on the corresponding checkboxes then saving.",
            "Le statut représente les langues actives pour votre campagne (Active si coché), vous pouvez les désactiver ou activer en cliquant sur la case à cocher correspondante puis en enregistrant."
          ],
          'geoTargeting' : [
            "The geo targeting type allows you to control which audience you want to target with your targeted locations.",
            "Le type de géociblage vous permets de controler l'audience que vous voulez cibler avec les emplacements ciblés."
          ],
          'languagesTip' : [
            "This settings affects all your strategies and not only the language preselected",
            "Ces paramètres affectent toutes vos stratégies et non pas seulement la langue présélectionnée"
          ],
          'adsTip' : [
            "You can drag and drop tags in input areas instead of handwriting them",
            "Vous pouvez faire glisser les tags dans les zones de saisie au lieu de les écrire à la main "
          ]
        },
        'creation' : {
          'nameAndLanguages' : [
            "Name and languages",
            "Nom et langues"
          ],
          'keywords' : [
            "Keywords",
            "Mots clés"
          ],
          'existingAdGroupName' : [
            "Already existing ad group name",
            "Le nom du groupe d'annonces existe déjà"
          ],
          'chooseKeywords' : [
            "Choose your positive keywords for your selected language(s)",
            "Choisissez vos mots clés positifs pour la ou les langues sélectionnées"
          ],
          'audienceAdCreator' : [
            "Add the audience(s) you want to associate with your ad group",
            "Ajoutez l'audience que vous souhaitez associer à votre groupe d'annonces"
          ],
          'currentAudiences' : [
            "Current audiences",
            "Audiences actuelles"
          ],
          'leaveMessage' : [
            "Are you sure you want to exit ? All your modifications will be lost if you proceed",
            "Êtes-vous sûr de vouloir quitter ? Toutes vos modifications seront perdues si vous procédez"
          ],
          'missingLanguage' : [
            "Missing language configuration",
            "Configuration d'une langue manquante"
          ],
          'nextStep' : [
            "Next",
            "Suivant"
        ],
        'previousStep' : [
            "Previous",
            "Précédent"
        ],
        'chooseOption':[
          "Choose an option",
          "Choisissez une option"
        ],
        'startFromScratch' : [
          "Start from scratch",
          "Commencer à partir de zéro"
        ],
        'fromExistingTemplates':[
          "Import from existing templates",
          "Importer à partir de modèles existants"
        ],
        }
    },
    'pacyPlanner' : {
        'title' : [
            "Budget planner",
            "Planificateur de budget"
        ],
        'planningChartTitle' : [
            "Division of your investment between budgets",
            "Division de votre investissement entre les budgets"
        ],
        'currentMonth' : [
            "Current month",
            "Mois en cours"
        ],
        'investment' : [
            "Investment",
            "Investissement"
        ],
        'spentSoFar' : [
            "Spent so far",
            "Dépensé à date"
        ],
        'openPreciseToggle' : [
            "Fine tuning of your investment",
            "Configuration manuelle de votre investissement"
        ],
        'closePreciseToggle' : [
            "Close",
            "Réduire"
        ],
        'vagueModalContent' : [
            "Investing ${amount} in ${period}",
            "Investir ${amount} en ${period}"
        ],
        'preciseModalContent' : [
            "Set your investment precisely",
            "Configurer votre investissement de façon précise"
        ],
        'guidelineCarryTooltip' : [
            "The investment was copied from the previous month",
            "L'investissement a été copié du dernier mois"
        ],
        'clickPreciseTooltip' : [
            "Click to set ${month}'s amounts",
            "Cliquez pour configurer les montant de ${month}"
        ],
        'budget' : [
            "Budget",
            "Budget"
        ],
        'disabled' : [
          "This budget is disabled",
          "Ce budget est désactivé"
        ],
        'attemptingSpend' : [
          "This budget will try to spend ${amount} this month",
          "Ce budget tentera de dépenser ${amount} ce mois-ci"
        ],
        'receivingInvestment' : [
          "This budget will receive ${percentage}% of the daily investment (${amount} over the month)",
          "Ce budget recevra ${percentage}% de l'investissement quotidien (${amount} au courant du mois)"
        ],
        'modalActive' : [
          "This budget is active.",
          "Ce budget est actif."
        ],
        'modalInactive' : [
          "This budget is inactive.",
          "Ce budget est inactif."
        ],
        'failed' : [
          "Failed to load the Pacy account. Please refresh your browser and try again. If the problem persist, contact technical support.",
          "Le compte Pacy ne peut être chargé. Veuillez rafraîchir votre navigateur et essayez de nouveau. Si le problème persiste, contactez le soutien technique."
        ],
        'deactivated' : {
          'generalMessage' : [
            "Pacy has been deactivated for this account.",
            "Pacy a été désactivé pour ce compte."
          ],
          'contact' : [
            "If you think this is a mistake, please contact your DealerBreacher representative.",
            "Si vous pensez que c'est une erreur, veuillez contacter votre représentant DealerBreacher."
          ],
          'advancedConfig' : [
            "You can reactivate Pacy in the Advanced configuration section.",
            "Vous pouvez réactiver Pacy dans la section Configuration avancée."
          ],
        }
    },
    'pacyChangesHistory' : {
        'clearFilters' : [
            "Clear",
            "Vider"
        ],
        'changesHistory' : [
          "Changes History",
          "Historique des Changements"
        ],
        'changeType' : {
          'title' : [
            "Change Type",
            "Type de changement"
          ],
          'guideline' : [
            "Investment",
            "Investissement"
          ],
          'pacingRatio' : [
            "Pacing ratio",
            "Pacing ratio"
          ],
          'pacingRatioChange' : [
            "Pacing ratio change",
            "Changement du pacing ratio"
          ],
          'enablement' : [
            "Pacy Enablement",
            "Activation de Pacy"
          ],
        },
        'status' : {
          'status' : [
            "Status",
            "Statut"
          ],
          'activated' : [
            "Activated",
            "Activé"
          ],
          'deactivated' : [
            "Deactivated",
            "Désactivé"
          ],
        },
        'channel' : [
          "Channel",
          "Canal"
        ],
        'channels' : [
          "Channels",
          "Canaux"
        ],
        'from' : [
          "From",
          "De"
        ],
        'to' : [
          "to",
          "à"
        ]
    },
    'geoTarget' : {
        'errors' : {
          'latitude' : [
            'Latitude should be between  -85.05112878 and 85.05112878',
            'La latitude doit être comprise entre -85.05112878 et 85.05112878'
          ],
          'longitude' : [
            'Longitude should be between -180 and 180',
            'La longitude doit être comprise entre -180 et 180'
          ]
        },
        'placeSearch' :[
          'place(s)',
          'endroit(s)'
        ],
        'found' : [
            "found",
            "trouvé(s)"
        ],
        'addRadius' : [
            "You can click on the map to add a radius",
            "Vous pouvez ajouter un rayon en cliquant sur la carte"
        ],
        'title' : [
            "Geo target",
            "Geo ciblage"
        ],
        'subtitle' : [
            "Click on a map to edit it",
            "Appuyez sur une carte pour la modifier"
        ],
        'reset' : [
            "Reset",
            "Réinitialiser"
        ],
        'save' : [
            "Save Params",
            "Sauvegarder paramètres"
        ],
        'saveInvalid' : [
            'Save error',
            "Erreur d'enregistrement"
        ],
        'saveInvalidMessage' : [
            "Empty geo targeting is not a valid configuration.",
            "Le ciblage géographique vide n'est pas une configuration valide."
        ],
        'resetDefault' : [
            "Reset default",
            "Réinitialiser par défaut"
        ],
        'continueEditing' : [
            "Continue editing",
            "Continuer l'édition"
        ],
        'copyAndReplace' : {
            'openButton' : [
              "Copy & Replace",
              "Copier & Remplacer"
            ],
            'subtitle' : [
              "Copy and replace geos",
              "Copier et remplacer des geos"
            ],
            'explanationMessage1' : [
              "Click on the geo that you want to copy",
              "Cliquez sur le geo à copier"
            ],
            'explanationMessage2' : [
              "Copied geos:",
              "Geos copiés:"
            ],
            'explanationMessage3' : [
              "Select geos to be replaced:",
              "Sélectionnez les geos à remplacer"
            ],
        },
        'resetMessage' : [
            "Are you sure you want to reset the current changes?",
            "Êtes-vous certain de vouloir réinitialiser les changements actuels?"
        ],
        'saveMessage' : [
            "Are you sure you want to save the current changes?",
            "Êtes-vous certain de vouloir sauvegarder les changements actuels?"
        ],
        'oneRadius' : [
            "Radius",
            "Rayon"
        ],
        'radius' : [
            "Radiuses",
            "Rayons"
        ],
        'position' : [
            "Position",
            "Position"
        ],
        'radiusKM' : [
            "Radius (KM)",
            "Rayon (KM)"
        ],
        'bidModifier' : [
            "Bid modifier",
            "Ajustement d'enchères"
        ],
        'radiusError' : [
            "Radius should be a number between 1 and 500",
            "Rayon doit être un nombre entre 1 et 500"
        ],
        'draggableRadiusError' : [
            'Radius cannot exceed 500km',
            'Le rayon ne peut pas dépasser 500 km'
        ],
        'bidErrorNaN' : [
            "Bid modifier must be a number between -90% and +900%",
            "Un ajustement d'enchères doit être un nombre entre -90% et +900%"
        ],
        'bidErrorTooHigh' : [
            "Bid modifier cannot exceed +900%",
            "Un ajustement d'enchères ne peut pas dépasser +900%"
        ],
        'bidErrorTooLow' : [
            "Bid modifier cannot be less than -90%",
            "Un ajustement d'enchères ne peut pas être inférieur à -90%"
        ],
        'places' : [
            "Places",
            "Endroits"
        ],
        'placesTitle' : [
            "Search for a new place",
            "Recherchez pour un nouvel endroit"
        ],
        'placesPlaceholder' : [
            "Type here to search for a location",
            "Tapez ici pour rechercher un nouvel emplacement"
        ],
        'name' : [
            "Name",
            "Nom"
        ],
        'details' : [
            "Details",
            "Détails"
        ],
        'selected' : [
            "Selected",
            "Sélectionné"
        ],
        'type' : [
            "Type",
            "Type"
        ],
        'include' : [
            "Include",
            "Inclure"
        ],
        'exclude' : [
            "Exclude",
            "Exclure"
        ],
        'targetingType' : [
            "Targeting type",
            "Type de ciblage"
        ],
        'inclusion' : [
            "Inclusion",
            "Inclusion"
        ],
        'exclusion' : [
            "Exclusion",
            "Exclusion"
        ],
        'location' : [
            "Location",
            "Emplacement"
        ],
        'placesTypes' : {
            'country' : [
                "Country",
                "Pays"
            ],
            'state' : [
                "State",
                "État"
            ],
            'province' : [
                "Province",
                "Province"
            ],
            'territory' : [
                "Territory",
                "Territoire"
            ],
            'department' : [
                "Department",
                "Département"
            ],
            'region' : [
                "Region",
                "Région"
            ],
            'county' : [
                "County",
                "Comté"
            ],
            'city' : [
                "City",
                "Ville"
            ],
            'cityRegion' : [
                "City region",
                "Région de ville"
            ],
            'canton' : [
                "Canton",
                "Canton"
            ],
            'municipality' : [
                "Municipality",
                "Municipalité"
            ],
            'nationalPark' : [
                "National park",
                "Parc national"
            ],
            'borough' : [
                "Borough",
                "Arrondissement"
            ],
            'neighborhood' : [
                "Neighborhood",
                "Quartier"
            ],
            'postalCode' : [
                "Postal code",
                "Code postal"
            ],
            'congressionalDistrict' : [
                "Congressional district",
                "District électoral"
            ],
            'airport' : [
                "Airport",
                "Aéroport"
            ],
            'university' : [
                "University",
                "Université"
            ],
            'autonomousCommunity' : [
                "Autonomous community",
                "Communauté autonome"
            ],
        },
    },
    'adCopies' : {
        'Branding' : [
          "Branding",
          "Marque"
        ],
        'Conquest' : [
          "Conquest",
          "Conquête"
        ],
        'Credit' : [
          "Finance",
          "Financement"
        ],
        'Finance' : [
          "Finance",
          "Financement"
        ],
        'Inventory - New' : [
          "New cars",
          "Véhicules neufs"
        ],
        'Inventory - Used' : [
          "Used cars",
          "Véhicules d'occasion"
        ],
        'Service' : [
          "Service",
          "Service"
        ],
        'allAdgroups' : [
          "All Adgroups",
          "Tout les groupes d'annonces"
        ],
        'failedLoadingAudiences' : [
          "An error occured when trying to load AW Search Audiences",
          "Une erreur est survenue en essayant d'obtenir les audiences AW"
        ],
        'failedLoadingAudiencesExtraInfo' : [
          "If you think this shouldn't be happening, notify the dev team by opening a ticket",
          "Si vous croyez que c'est un problème, avertissez l'équipe dev en ouvrant un ticket"
        ],
        'audience' : [
          "Audience",
          "Audience"
        ],
        'adManager' : [
          "Ad manager",
          "Gestionnaire d'annonces"
        ],
        'saveSnack' : [
          "Your ad copy is currently being saved !",
          "Votre annonce est en cours de sauvegarde !"
        ],
        'saveErrorSnack' : [
          "Error while saving adcopy",
          "Une erreur est survenue en sauvegardant"
        ],
        'unpinned' : [
          "Show in any unpinned position",
          "Affiché parmi toutes les positions disponibles"
        ],
        'pinPos1' : [
          "Show only in position 1",
          "Affiché seulement en position 1"
        ],
        'pinPos2' : [
          "Show only in position 2",
          "Show only in position 2"
        ],
        'pinPos3' : [
          "Show only in position 3",
          "Show only in position 3"
        ],
        'strategy' : [
          "Strategy",
          "Stratégie"
        ],
        'campaignName' : [
          "Campaign name",
          "Nom de la campagne"
        ],
        'copy' : [
          "Copy ad copy for another group",
          "Copier l'annonce pour un autre groupe"
        ],
        'adGroup' : [
          "Adgroup",
          "Groupe d'annonces"
        ],
        'language' : [
          "Language",
          "Langue"
        ],
        'title' : [
            "Ads Manager",
            "Gestionnaire d'annonce"
        ],
        'subtitle' : [
            "Choose a category",
            "Choisissez une catégorie"
        ],
        'newSubtitle' : [
            "Edit your adcopies for new cars",
            "Modifiez vos adcopies pour véhicules neufs"
        ],
        'usedSubtitle' : [
            "Edit your adcopies for new cars",
            "Modifiez vos adcopies pour véhicules usagés"
        ],
        'conquestSubtitle' : [
            "Edit your adcopies for conquest campaigns",
            "Modifiez vos adcopies pour campagnes de conquête"
        ],
        'inventoryTesterExplanation' : [
            "List of which car in your inventory fits or not this adcopy.",
            "Liste des véhicules de votre inventaire touchés par cette annonce"
        ],
        'noAdCopies' : [
            "No adcopies for selected options",
            "Aucun adcopies pour les options sélectionnées"
        ],
        'createAdCopy' : [
            "Add an AdCopy",
            "Ajouter une annonce"
        ],
        'addNew' : [
            "ADD A NEW AD",
            "AJOUTER UNE ANNONCE"
        ],
        'addHeadline' : [
            "ADD HEADLINE",
            "AJOUTER UN TITRE"
        ],
        'addDescription' : [
            "ADD DESCRIPTION",
            "AJOUTER UNE DESCRIPTION"
        ],
        'tagTitle' : [
          "Edit your adcopy faster by using those automated tags (drag & drop)",
          "Éditez vos campagnes plus rapidement en utilisant les étiquettes (glissez-déposez)"
        ],
        'removeAdCopy' : [
            "Delete this AdCopy",
            "Supprimer cette annonce"
        ],
        'editorLimitError' : [
            "Character limit exceeded",
            "Limite de caractères dépassée"
        ],
        'editorMinCharError' : [
            "${name} must be at least ${value} characters long.",
            "${name} doit contenir au moins ${value} caractères."
        ],
        'editorMinLengthError' : [
            "The text must be at least ${value} characters long.",
            "Le texte doit contenir au moins ${value} caractères."
        ],
        'editorAlphabeticalError' : [
            "${name} must contain alphabetical characters.",
            "${name} doit contenir des caractères alphabetiques."
        ],
        'editorTitle' : [
            "Editing adcopy",
            "Édition d'une annonce"
        ],
        'creatingTitle' : [
            "Creating adcopy",
            "Création d'une anonce"
        ],
        'numberValid' : [
            "Valid for ${value} vehicles",
            "Valide pour ${value} véhicules"
        ],
        'numberInvalid' : [
            "Invalid for ${value} vehicles",
            "Invalide pour ${value} véhicules"
        ],
        'allGood' : [
            "All good! Valid for all vehicles",
            "Tout est bon! Valide pour tous les véhicules"
        ],
        'errorInvalidTag' : [
            "Invalid tag detected",
            "Marqueur invalide détecté"
        ],
        'errorNonStandardSpace' : [
            "Non-standard space detected at position ${position}",
            "Espace non-standard détecté à la position ${position}"
        ],
        'errorZeroDollar' : [
            "Amounts of 0 dollar are forbidden",
            "Les montants de 0 dollar sont interdit"
        ],
        'errorHeadlineExclamation' : [
            "Exclamation points are forbidden in headlines",
            "Les points d'exclamation sont interdit dans les titres"
        ],
        'errorTooManyExclamation' : [
            "You can't have more than one exclamation point in an adcopy",
            "Vous ne pouvez pas avoir plus d'un point d'exclamation dans une annonce"
        ],
        'errorInvalidCharacters' : [
            "The following character(s) are invalid: ${characters}",
            "Les charactères suivant sont invalide: ${characters}"
        ],
        'errorInvalidTags' : [
            "The following tag(s) do not exist: ${tags}",
            "Les marqueur(s) suivant n'existent pas: ${tags}"
        ],
        'errorForbiddenWords' : [
            "The following word(s) are forbidden: ${words}",
            "Les mot(s) suivant sont interdit: ${words}"
        ],
        'errorTextNumber' : [
            "Google requires at least 3 headlines and 2 descriptions",
            "Google requiert au moins 3 titres et 2 descriptions"
        ],
        'errorsInTemplates' : [
            "There are errors in the templates",
            "Les annonces contiennent des erreurs"
        ],
        'errorsInOtherTemplates' : [
            "Please ensure that all templates are valid",
            "Veuillez vous assurer que tous les annonces sont corrects"
        ]
    },
    'keywords' : {
        'title' : [
            "Keywords",
            "Mots-clés"
        ],
        'subtitle' : [
            "Choose a category",
            "Choisissez une catégorie"
        ],
        'newSubtitle' : [
            "Edit your keywords for new cars",
            "Modifiez vos mots-clés pour véhicules neufs"
        ],
        'usedSubtitle' : [
            "Edit your keywords for used cars",
            "Modifiez vos mots-clés pour véhicules usagés"
        ],
        'noKeywords' : [
            "No keywords in the selected category",
            "Aucun mots-clés dans la catégorie sélectionnée"
        ],
        'posKeywords' : [
            "Positive keywords",
            "Mots-clés positifs"
        ],
        'negKeywords' : [
            "Negative keywords",
            "Mots-clés négatifs"
        ],
    },
    'rules' : {
        'title' : [
            "Engagement rules",
            "Règles d'engagement"
        ],
        'subtitle' : [
            "Choose a category",
            "Choisissez une catégorie"
        ],
        'newSubtitle' : [
            "Edit your engagement rules for new cars",
            "Modifiez vos règles d'engagement pour véhicules neufs"
        ],
        'usedSubtitle' : [
            "Edit your engagement rules for used cars",
            "Modifiez vos règles d'engagement pour véhicules usagés"
        ],
        'newPlural' : [
            "new",
            "neufs"
        ],
        'usedPlural' : [
            "used",
            "usagés"
        ],
        'editorSubtitle' : [
            "Engagement Rules for ${state} cars",
            "Règles d'engagement pour véhicules ${state}"
        ],
        'addFilter' : [
            "Add a new filter",
            "Ajouter un nouveau filtre"
        ],
        'whichRule' : [
            "Which rule to add",
            "Quelle règle ajouter"
        ],
        'picturesRule' : [
            "Number of Pictures",
            "Nombre de photos"
        ],
        'daysRule' : [
            "Vehicle's number of days in inventory",
            "Nombre de jours en inventaire du véhicule"
        ],
        'yearRule' : [
            "Vehicle's Year",
            "Année du véhicule"
        ],
        'priceRule' : [
            "Vehicle's Price",
            "Prix du véhicule"
        ],
        'makeRule' : [
            "Vehicle's Make",
            "Fabriquant du véhicule"
        ],
        'modelRule' : [
            "Vehicle's Model",
            "Modèle du véhicule"
        ],
        'stockNumberRule' : [
            "Vehicle's stock number",
            "Numéro de stock du véhicule"
        ],
        'atLeast' : [
            "At least",
            "Au moins"
        ],
        'atMost' : [
            "At most",
            "Au plus"
        ],
        'isNot' : [
            "Is not",
            "N'est pas"
        ],
        'is' : [
            "Is",
            "Est"
        ],
        'isNotRegex' : [
            "Does not match",
            "Ne correspond pas à"
        ],
        'isRegex' : [
            "Matches",
            "Correspond à"
        ],
        'dragRule' : [
            "Drag a rule here to add it",
            "Glissez une règle ici pour l'ajouter"
        ],
        'addedOn' : [
            "Added on ${date} by ${someone}",
            "Ajouté le ${date} par ${someone}"
        ],
        'addRule' : [
            "Add a rule to this filter",
            "Ajouter une règle à ce filtre"
        ],
        'incorrectValue' : [
            "Incorrect value",
            "Valeur érronée"
        ],
        'AND' : [
            "AND",
            "ET"
        ],
        'OR' : [
            "OR",
            "OU"
        ],
    },
    'strategies' : {
        [GeoTargetingTypes.DONT_CARE] : [
          "Default",
          "Défaut"
        ],
        [GeoTargetingTypes.AREA_OF_INTEREST] : [
          "Area of Interest",
          "Lieu d'Intérêt"
        ],
        [GeoTargetingTypes.LOCATION_OF_PRESENCE] : [
          "Location of Presence",
          "Lieu de Présence"
        ],
        'title' : [
            "Strategies",
            "Stratégies"
        ],
        'subtitle' : [
            "View your strategies",
            "Visualisez vos stratégies"
        ],
        'notAllowedNotice' : [
            "You are not allowed to edit strategies",
            "Vous n'êtes pas autorisé à modifier les strategies"
        ],
        'budgetId' : [
            "Budget ID",
            "ID budget"
        ],
        'biddingStrategyId' : [
            "Bidding strategy ID",
            "ID stratégie de mise"
        ],
        'biddingStrategy' : [
            "Bidding Strategy",
            "Stratégie de mise"
        ],
        'biddingStrategies' : [
            "Bidding Strategies",
            "Stratégies de mise"
        ],
        'geoTargetingType' : [
            "Geotargeting Type",
            "Type de Géociblage"
        ],
        'status' : [
            "Status",
            "Statut"
        ],
        'budgetRole' : [
            "Budget role",
            "Rôle du budget"
        ],
        'amount' : [
            "Amount",
            "Montant"
        ],
        'language' : [
            "Language",
            "Langue"
        ],
        'group' : [
            "Group",
            "Groupe"
        ],
        'vehicleState' : [
            "State",
            "État"
        ],
        'vehicleType' : [
            "Vehicle Type",
            "Type de véhicule"
        ],
        'finalUrl' : [
            "Final URL",
            "URL de destination"
        ],
        'bidSetting' : [
          "Bid Setting",
          "Enchères"
        ],
        'biddingStrategyType' : [
          "Bidding Strategy",
          "Stratégie d'enchères"
        ],
        'Portfolio' : [
          "Portfolio",
          "Portefeuille"
        ],
        'Campaign-level' : [
          "Campaign-Level",
          "Niveau campagne"
        ],
        'TARGET_SPEND' : [
          "Maximize Click",
          "Clics"
        ],
        'MAXIMIZE_CONVERSIONS' : [
          "Maximize Conversion",
          "Conversions"
        ],
        'MAXIMIZE_CONVERSION_VALUE' : [
          "Maximize Conversion Value",
          "Valeur de conversion"
        ],
        'TARGET_IMPRESSION_SHARE' : [
          "Target Impression Share",
          "Taux d'impressions"
        ],
        'properties' : {
          'maxCpc' : [
            "Set a maximum cost per click bid limit",
            "Définir une limite d'enchère au coût par clic maximale"
          ],
          'adLocation' : [
            "Ad Location",
            "Emplacement de l'annonce"
          ],
          'ANYWHERE_ON_PAGE' : [
            "Anywhere on results page",
            "N'importe ou sur la page des résultats"
          ],
          'TOP_OF_PAGE' : [
            "Top of results page",
            "En haut de la page des résultats"
          ],
          'ABSOLUTE_TOP_OF_PAGE' : [
            "Absolute Top of results page",
            "Tout en haut de la page des résultats"
          ],
          'percent' : [
            "Percent impression share to target",
            "Taux d'impressions cible"
          ],
          'maxBidLimit' : [
            "Set a maximum CPC bid limit",
            "CPC maximale"
          ],
          'targetRoas' : [
            "Set a target return on ad spend",
            "ROAS cible"
          ],
          'targetCpa' : [
            "Set a target cost per action",
            "CPA cible"
          ],
          'targetImpressionShareRequired' : [
            "Percent impression share and Max CPC are required",
            "ROAS et CPA cibles sont requis"
          ],
        },
        'editModal' : {
            'title' : [
                "Edit Strategy",
                "Modifier Stratégie"
            ],
            'biddingStrategiesInputPlaceholder' : [
                "Filter bidding strategies",
                "Filtrer les stratégies de mise"
            ],
        },
    },
    'profile' : {
        'title' : [
            "Profile",
            "Profil"
        ],
        'subtitle' : [
            "Information about you",
            "Informations à propos de vous"
        ],
        'id' : [
            "ID",
            "ID"
        ],
        'email' : [
            "Email",
            "Courriel"
        ],
        'userLevel' : [
            "User Level",
            "Niveau d'utilisateur"
        ],
        'officePhone' : [
            "Office Phone",
            "Téléphone du bureau"
        ],
        'cellPhone' : [
            "Cell phone",
            "Téléphone cellulaire"
        ],
        'logout' : [
            "Logout",
            "Se déconnecter"
        ],
        'languageTitle' : [
            "Choose the interface's language",
            "Choisissez la langue de l'interface"
        ],
        'localeTitle' : [
            "Choose your locale",
            "Choisissez votre région"
        ]
    },
    'loadings' : {
        'userPermissions' : [
          "Loading target user permissions",
          "Chargement des permissions de l'utilisateur cible"
        ],
        'targetUserPreferences' : [
          "Loading target user preferences",
          "Chargement des préférences de l'utilisateur cible"
        ],
        'adGroups' : [
          "Loading ad groups",
          "Chargement des groupes d'annonces"
        ],
        'loading' : [
          'Loading',
          'Chargement en cours'
        ],
        'grammarBooster' : {
            'replacements' : [
                "Loading Replacements",
                "Chargement des remplacements",
            ]
        },
        'whiteLabels' : [
            "Loading white labels",
            "Chargement des white labels"
        ],
        'inventory' : [
            "Loading inventory",
            "Chargement de l'inventaire"
        ],
        'accounts' : [
            "Loading accounts",
            "Chargement des comptes"
        ],
        'budgetPlans' : [
            "Loading budget plans",
            "Chargement des plans budgétaire"
        ],
        'budgets' : [
            "Loading budgets",
            "Chargement des budgets"
        ],
        'leads' : [
            "Loading leads",
            "Chargement des prospects"
        ],
        'navigations' : [
            "Loading navigations",
            "Chargement des navigations"
        ],
        'permissionProfiles' : [
            "Loading permission profiles",
            "Chargement des profils de permission"
        ],
        'userData' : [
            "Loading user data",
            "Chargement des données de l'utilisateur"
        ],
        'cleanerData' : [
            "Loading Vehicles Reference Data",
            "Chargement du catalogue de véhicules"
        ],
        'usersPermissionProfiles' : [
            "Loading users permission profiles",
            "Chargement des profils de permission des utilisateurs"
        ],
        'saving' : [
            "Saving",
            "Sauvegarde en cours"
        ],
        'deleting' : [
            "Deleting",
            "Suppression en cours"
        ],
        'adCopies' : [
            "Loading adcopies",
            "Chargement des adcopies"
        ],
        'data' : [
            "Loading data",
            "Chargement des données"
        ],
        'budgetsStats' : [
            "Loading budgets stats",
            "Chargement des statistiques des budgets"
        ],
        'campaigns' : [
            "Loading campaigns",
            "Chargement des campagnes"
        ],
        'campaign' : [
            "Loading campaign",
            "Chargement de la campagne"
        ],
        'campaignsInfo' : [
            "Loading campaigns info",
            "Chargement des informations de campagnes"
        ],
        'geoData' : [
            "Loading geo data",
            "Chargement des données géographique"
        ],
        'stats' : [
            "Loading statistics",
            "Chargement des statistiques"
        ],
        'compiling' : [
            "Compiling data",
            "Compilation des données"
        ],
        'geoTargeting' : [
            "Loading geo targeting",
            "Chargement du ciblage géographique"
        ],
        'keywords' : [
            "Loading keywords",
            "Chargement des mots-clés"
        ],
        'strategies' : [
            "Loading strategies",
            "Chargement des stratégies"
        ],
        'savingView' : [
            "Saving view",
            "Sauvegarde de la vue"
        ],
        'switchingView' : [
            "Switching view",
            "Changement de vue"
        ],
        'shapes' : [
            "Loading shapes",
            "Chargement des formes"
        ],
        'geocoding' : [
            "Geocoding places",
            "Géocodage des places"
        ],
        'wazeCompanies' : [
            "Loading Waze companies",
            "Chargement des compagnies Waze"
        ],
        'wazeStats' : [
            "Loading Waze stats",
            "Chargement des statistiques de Waze"
        ],
        'noWazeAccount' : [
          "No Waze account linked",
          "Aucun compte Waze lié"
        ],
        'rules' : [
            "Loading engagement rules",
            "Chargement des règles d'engagement"
        ],
        'campaignsStats' : [
            "Loading campaigns stats",
            "Chargement des statistiques des campagnes"
        ],
        'biddingStrategies' : [
            "Loading bidding strategies",
            "Chargement des strategies de mise"
        ],
        'marketingEvents' : [
            "Loading marketing events",
            "Chargement des évenements marketing"
        ],
        'AWSearchAudiences' : [
            "Loading search audiences",
            "Chargement des audiences de recherche"
        ],
        'feeds' : [
            "Loading feeds",
            "Chargement des flux de données"
        ],
        'availableFeeds' : [
            "Loading available feeds",
            "Chargement des flux de données disponibles"
        ],
        'pacy' : [
            "Loading budgets data",
            "Chargement des données de budgets"
        ],
        'saveGuideline' : [
            "Saving investment",
            "Sauvegarde de l'investissement"
        ],
        'migrating' : [
            "Migrating",
            "Migration en cours"
        ],
        'budgetPlanner' : [
            "Loading budget planner",
            "Chargement du planificateur de budget"
        ],
        'pacingRatio' : [
            "Saving pacing ratio configuration",
            "Sauvegarde de la configuration du pacing ratio"
        ],
        'identityVariants' : [
          "Loading Identity Variants",
          "Chargement des Variantes d'identité"
        ],
        'adGroupConfig' : [
          "Loading AdGroup Config",
          "Chargement des configurations des AdGroups"
        ],
        'brandingKeywords' : [
          "Loading Keywords",
          "Chargement des mots clés"
        ],
        'brandingAudienceAdsSetup' : [
          "Setting up your audience ads",
          "Configuration de vos annonces"
        ]
    },
    'login' : {
        'email' : [
            "Email",
            "Courriel"
        ],
        'password' : [
            "Password",
            "Mot de passe"
        ],
        'rememberMe' : [
            "Remember Me",
            "Souvenez-vous de moi"
        ],
        'login' : [
            "Login",
            "Connexion"
        ],
        'sessionExpired' : [
            "Your session has expired, please click here to login again",
            "Votre session a expirée, veuillez cliquer ici pour vous connecter à nouveau"
        ],
        'maintenance' : {
          'notice' : [
              "Please note that the application will be under maintenance and unavailable during the following period",
              "Veuillez prendre note que l'application sera en maintenance et indisponible durant la période suivante"
          ],
          'unavailable' : [
              "The application is currently unavailable due to an ongoing scheduled maintenance. The service will resume at",
              "L'application est présentement indisponible dû à une maintenance en cours. Le service reprendra le"
          ],
          'start' : [
              "Start",
              "Début"
          ],
          'end' : [
              "End",
              "Fin"
          ],
        },
        'forgotPassword' : [
            "Forgot Password?",
            "Mot de passe oublié?"
        ],
        'errors' : {
            'invalidPassword' : [
                "Invalid password",
                "Mot de passe invalide"
            ],
            'invalidEmail' : [
                "Invalid email",
                "Courriel invalide"
            ],
        }
    },
    'forgotPassword' : {
        'yourEmail' : [
            "Your email",
            "Votre courriel"
        ],
        'resetPassword' : [
            "Reset Password",
            "Réinitialiser mot de passe"
        ],
        'doneMessage' : [
            "An email will be sent to you shortly. Please check your inbox (and spam folder)",
            "Un courriel va vous être envoyé sous peu. Vérifiez votre boîte de réception (et vos courriers indésirables)"
        ],
        'return' : [
            "Return to Login",
            "Retourner à la connexion"
        ],
    },
    'adBuilder' : {
        'title' : [
          'Ad builder',
          'Ad builder'
        ],
        'copy' : [
          "Copy",
          "Copie"
        ],
        'saved' : [
          "Your Marketing Approach has been successfully copied!",
          "Votre approche marketing a été enregistrée avec succès!"
        ],
        'noEvents' : [
          "No marketing approaches are available",
          "Aucune approche marketing disponible"
        ],
        'newMarketingApproach' : [
          'New marketing approach',
          'Nouvelle approche marketing'
        ],
        'noAvailableInventory' : [
            "No current vehicles match the boundaries selected in Step 1",
            "Aucun véhicule actuel ne correspond aux limites sélectionnées à l'étape 1"
        ],
        'rowsToCullAlert' : {
            'headline' : [
                "Some vehicles' text is too long for this headline",
                "Le texte de certains véhicules est trop long pour ce titre"
            ],
            'description' : [
                "Some vehicles' text is too long for this description",
                "Le texte de certains véhicules est trop long pour cette description"
            ],
            'culledRows' : [
                'The following vehicles will not be used to create ads because the length of the ads would be too long:',
                "Les véhicules suivants ne seront pas utilisés pour créer des annonces car ces dernières seraient trop longues:"
            ],
            'unculledRows' : [
                'These vehicles are valid:',
                "Ces véhicules sont valide:"
            ],
            'inStock' : [
                'In Stock',
                "En inventaire"
            ]
        },
        'audienceSearch' : {
          'audienceIsEmpty' : [
            "This audience is empty",
            "Cette audience est vide"
          ],
          'searchAudiences' : [
            "Search..",
            "Recherche.."
          ],
        },
        'readOnlyModal' : {
            'title' : [
                "Read only mode",
                "Mode lecture seule"
            ],
            'content' : [
                "You are not allowed to do modifications to the marketing approaches of this account. You can explore the section to see the what has been created but none of your changes will be saved.",
                "Vous n'êtes pas autorisé à faire des modifications aux approches marketings de ce compte. Vous pouvez explorer la section pour voir ce qui a été créé mais aucun de vos changements seront sauvegardé."
            ],
            'button1' : [
                "Proceed",
                "Procéder"
            ],
        },
        'status' : {
            'all' : [
              "ALL",
              "TOUT"
            ],
            'active' : [
              "ACTIVE",
              "ACTIF"
            ],
            'ended' : [
                "ENDED",
                "COMPLETÉ"
            ],
            'paused' : [
              "PAUSED",
              "EN PAUSE"
            ],
            'soon' : [
                "STARTING SOON",
                "DÉMARRERA BIENTÔT"
            ],
        },
        'newEventButton' : [
            "CREATE A NEW MARKETING APPROACH",
            "CRÉER UNE NOUVELLE APPROCHE MARKETING"
        ],
        'eventOverview' : {
            'adPreview' : [
              "Ad preview",
              "Aperçu des annonces"
            ],
            'newCars' : [
              "  New cars",
              "  Véhicules neufs"
            ],
            'usedCars' : [
              "  Used cars",
              "  Véhicules usagés"
            ],
            'boundariesCampaign' : [
                "Campaign :",
                "Campagne :"
            ],
            'boundariesState' : [
                "Campaign type :",
                "Type de campagne :"
            ],
            'boundariesMake' : [
                "Make :",
                "Marque :"
            ],
            'boundariesModel' : [
                "Model :",
                "Modèle :"
            ],
            'boundariesYear' : [
                "Year :",
                "Année :"
            ],
            'startDate' : [
                "Start date :",
                "Date de début :"
            ],
            'endDate' : [
                "End date :",
                "Date de fin :"
            ],
            'modifiedOnBy' : [
                "Modified on ${date} by ${who}",
                "Modifié le ${date} par ${who}"
            ],
            'modalTitle' : [
                "Delete a marketing approach",
                "Supprimer une approche marketing"
            ],
            'modalText1' : [
                "You are about to delete the marketing approach <strong>`${title}`</strong>",
                "Vous êtes sur le point de supprimer l'approche marketing <strong>`${title}`</strong>"
            ],
            'modalText2' : [
                "Do you want to proceed?",
                "Voulez-vous procéder?"
            ],
        },
        'createEvent' : {
            'USED' : [
              "Used cars",
              "Véhicules d'occasion"
            ],
            'NEW' : [
              "New cars",
              "Véhicules neufs"
            ],
            'BRANDING' : [
              "Branding",
              "Marque"
            ],
            'SERVICE' : [
              "Service",
              "Service"
            ],
            'CREDIT' : [
              "Credit",
              "Financement"
            ],
            'noBoundaries' : [
              'No boundaries to setup for non inventory event',
              'Pas de limites nécessaire pour les événements non-inventaires'
            ],
            'addCampaign' : [
              'ADD AN ADDITIONAL CAMPAIGN',
              'AJOUTER UNE CAMPAGNE SUPPLÉMENTAIRE'
            ],
            'addCondition' : [
              'ADD A NEW CONDITION',
              'AJOUTER UNE NOUVELLE CONDITION'
            ],
            'Or' : [
              'OR',
              'OU'
            ],
            'marketingApproachTitle' : [
                "Marketing approach title",
                "Nom de l'approche marketing"
            ],
            'campaignGroupSelection' : [
                "Campaign group selection",
                "Sélection du groupe de campagnes"
            ],
            'languageSelection': [
                "Language Selection",
                "Sélection de la langue"
            ],
            'boundariesTitle' : [
                "Vehicles and campaigns impacted",
                "Véhicules et campagnes touchés"
            ],
            'identityTitle' : [
                "Marketing approach identity",
                "Identité de l'approche"
            ],
            'nextStep' : [
                "Next step",
                "Étape suivante"
            ],
            'previousStep' : [
                "Previous step",
                "Étape précédente"
            ],
            'publish' : [
                "Publish",
                "Publier"
            ],
            'publishAndClose' : [
                "Publish & Close",
                "Publier & Fermer"
            ],
            'langSelectDefault' : [
                "Select ads language",
                "Sélectionnez la langue des annonces"
            ],
            'stateSelectDefault' : [
                "Select vehicle state",
                "Sélectionnez l'état du véhicule"
            ],
            'startDate' : [
                "Start date",
                "Date de début"
            ],
            'endDate' : [
                "End date",
                "Date de fin"
            ],
            "anyYear" : [
                "Any Year",
                "N'importe quelle année"
            ],
            "anyMake" : [
                "Any Make",
                "N'importe quel fabriquant"
            ],
            "anyModel" : [
                "Any Model",
                "N'importe quel modèle"
            ],
            'makeFirst' : [
                "Select Make First",
                "Sélectionner un fabriquant en premier"
            ],
            'chooseVehicle' : [
                "Choose a specific vehicle only",
                "Choisir un véhicule spécifique"
            ],
            'selectCampaign' : [
                "Select campaign",
                "Sélectionner une campagne"
            ],
            'originallySelected' : [
                "Originally selected",
                "Sélectionné originalement"
            ],
            'campaignNotExist' : [
                "This campaign does not exist anymore",
                "Cette campagne n'existe plus"
            ],
            'availableTags' : [
                "Available tags",
                "Marqueurs disponible"
            ],
            'headline' : [
                "Headline",
                "Titre"
            ],
            'description' : [
              "description",
              "description"
            ],
            'reviewVDP' : [
                "VDP campaigns",
                "Campagnes VDP"
            ],
            'reviewMM' : [
                "MM campaigns",
                "Campagnes MM"
            ],
            'reviewMMY' : [
                "MMY campaigns",
                "Campagnes MMY"
            ],
            'step1Title' : [
                "STEP ${number}",
                "ÉTAPE ${number}"
            ],
            'step2Title' : [
                "STEP ${number}",
                "ÉTAPE ${number}"
            ],
            'step3Title' : [
                "STEP ${number}",
                "ÉTAPE ${number}"
            ],
            'step4Title' : [
                "STEP ${number}",
                "ÉTAPE ${number}"
            ],
            'adCopyTitles' : {
                'VDP' : [
                    "VDP CAMPAIGNS ADCOPY",
                    "ADCOPY DES CAMPAGNES VDP"
                ],
                'SRP_MM' : [
                    "MM CAMPAIGNS ADCOPY",
                    "ADCOPY DES CAMPAGNES MM"
                ],
                'SRP_MMY' : [
                    "MMY CAMPAIGNS ADCOPY",
                    "ADCOPY DES CAMPAGNES MMY"
                ],
            },
            'checkboxes' : {
                'SRP_MM' : [
                    "Use the same ad copy for MM campaigns",
                    "Utiliser les même ad copy pour les campagnes MM"
                ],
                'SRP_MMY' : [
                    "Use the same ad copy for MMY campaigns",
                    "Utiliser les même ad copy pour les campagnes MMY"
                ],
                'VDP_RLSA' : [
                    "Use the same ad copy for VDP RLSA campaigns",
                    "Utiliser les même ad copy pour les campagnes VDP RLSA"
                ],
                'SRP_MM_RLSA' : [
                    "Use the same ad copy for MM RLSA campaigns",
                    "Utiliser les même ad copy pour les campagnes MM RLSA"
                ],
                'SRP_MMY_RLSA' : [
                    "Use the same ad copy for MMY RLSA campaigns",
                    "Utiliser les même ad copy pour les campagnes MMY RLSA"
                ],
                'checkedBy' : [
                    "(Using ${group} AdCopies)",
                    "(Annonces ${group} choisies)"
                ]
            },
            'errors' : {
                'noRows' : [
                    "No vehicles currently match these boundaries",
                    "Aucun véhicule ne correspond au filtre donné"
                ],
                'titleRequired' : [
                    "A title must be entered",
                    "Un titre doit être entré"
                ],
                'title4Char' : [
                    "Title must be minimally 4 characters long",
                    "Le titre doit être d'une longueur minimale de 4 caractères"
                ],
                'title128Char' : [
                    "Title length can't be over 128 characters",
                    "Le titre ne peut dépasser 128 caractères"
                ],
                'language' : [
                    "Language must be selected",
                    "La langue doit être sélectionnée"
                ],
                'state' : [
                    "Campaign group must be selected",
                    "Le groupe de campagnes doit être sélectionné"
                ],
                'startDate' : [
                    "Start date must be entered",
                    "La date de début doit être entrée"
                ],
                'endDate' : [
                    "End date must be entered",
                    "La date de fin doit être entrée"
                ],
                'audience' : [
                    "At least one audience must be selected",
                    "Au moins une audience doit être sélectionnée"
                ],
                'adcopies' : [
                    "Adcopies are too long",
                    "Les annonces sont trop longues"
                ],
                'invalidAdcopies' : [
                    "Not all adcopies are valid",
                    "Tous les adcopies ne sont pas valide"
                ],
            },
            'stepsDescription' : {
                'step1' : [
                    "Choose on which vehicles or vehicles group you want to customize adcopy.",
                    "Choisissez quel vehicles ou groupe de véhicules seront affecté"
                ],
                'step2' : [
                    "Create your new adcopy.",
                    "Créez vos nouveaux adcopy"
                ],
                'step3' : [
                    "Create your new adcopy for RLSA.",
                    "Créez vos nouveaux adcopy pour le RLSA"
                ],
                'step4' : [
                    "Review and publish your new adcopy.",
                    "Révisez et publiez vos nouveaux adcopy"
                ],
            },
            'congrats' : {
                'part1' : [
                    "CONGRATULATIONS !",
                    "FÉLICITATIONS !"
                ],
                'part2' : [
                    "Your new ad copy will be live by tomorrow morning.",
                    "Vos nouveaux adcopy vont être en ligne à partir de demain matin."
                ],
            },
        },
    },
    'permissions' : {
        'errorTitle' : [
            "Permission error",
            "Erreur de permission"
        ],
        'errorDescription' : [
            "You do not have the permissions required to access this module. Missing the following permission: '${permission}'",
            "Vous n'avez pas les permissions requises pour accéder à ce module. Il vous manque la permission suivante : '${permission}'"
        ],
    },
    'dialog' : {
      'deleteAdGroup' : [
        "Are you sure you want to delete this ad group ?",
        "Êtes-vous sûr de vouloir supprimer ce groupe d'annonces?"
      ],
      'deleteAudienceAd' : [
        "Are you sure you want to delete this audience ad ?",
        "Êtes-vous sûr de vouloir supprimer cette audience ?"
      ]
    }
}

export default translations
